import React, { useState, useEffect } from 'react';
import { Loader, Group, Text, Image, Button,Tooltip } from "@mantine/core";
import { IconArrowBackUpDouble, IconArrowLeftCircle, IconArrowLoopRight, IconCheck, IconCodeCircle, IconCopy, IconCopyPlus, IconCopyPlusFilled, IconDropletFilled, IconDropletPlus, IconPillFilled, IconPlus, IconRadioactive, IconRadioactiveFilled, IconTruckReturn } from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { set } from 'date-fns';

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export const RenewRadiologie = ({ encounterId }) => {
  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const radiologieData = useStore((state) => state.radiologieData);
  const setRadiologieData = useStore((state) => state.setRadiologieData);
  const { setActive, setActiveTab } = useStore();
  const [loading, setLoading] = useState(false);

 

  const addRadiologie = async (value) => {
    setLoading(true);
    const RadiologieData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      categoryContent: String("Radiologie").trim(),
      ServiceId: parseInt(value.ServiceId, 10),
      ServiceContent: [{ radiologie: value.ServiceContent[0].radiologie, tttAld:false }],
    };


    try {
      if (Array.isArray(radiologieData)) {
        const existingIndex = radiologieData.findIndex(
          (item) => item.ServiceId === value.ServiceId
        );

        if (existingIndex !== -1) {
            const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Text fw={700} size="xs">{value.ServiceContent[0].radiologie.examen} déjà Prescrit </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
           "api/service-request/",
          RadiologieData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            radiologieData.push({ ...RadiologieData, id: response.data.id });
            console.log(radiologieData)
            setRadiologieData(radiologieData);
            console.log("Ordonnance de Radiologie mise à jour");
    
           
        }
      
      };
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/encounters/${encounterId}/`);

      if (response.status === 200) {
        const ordonnance = response.data.encounterData.ordonnanceRadiologie;
        for (const radiologie of ordonnance || []) {
          await addRadiologie(radiologie);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
    label={
    
        <Text size="xs">
         Renouveler la Radiologie
        </Text>
      
    }
    color="#94ADA7"
    withArrow
    multiline
  
    position="top"
    radius="md"
    arrowOffset={25}
    arrowSize={8}
    transitionProps={{ transition: "pop", duration: 500 }}
    openDelay={1000}
  >
    <Button 
    className='renouvelerButton'
      color="#94ADA7"
      radius="md"
      size="xs"
      onClick={() => {
       
        renouvelerOrdo();
        setActive("radiologie")
        setActiveTab("radiologie")
      }} 
      loading={loading} 
      disabled={loading}
    >
      <IconPlus size={16}/>
      <IconRadioactiveFilled size={18}/>
    
    </Button>
    </Tooltip>
   
 
  );
};

