import { useStore } from "../Store";
import { useState } from "react";
import "@mantine/core/styles.css";
import { SegmentedControl } from "@mantine/core";

export const AntecedentsType = () => {
  const antecedentSearchType = useStore((state) => state.antecedentSearchType);
  const setAntecedentSearchType = useStore(
    (state) => state.setAntecedentSearchType
  );

  const data = [
    { value: 'Medical', label: 'Medical' },
    { value: 'Chirurgical', label: 'Chirurgical' },
    { value: 'Familial', label: 'Familial' },
    { value: 'Allergie', label: 'Allergie' },
  ];

  const [active, setActive] = useState(data[0].value);

  const handleControlChange = (value) => {
    setActive(value);
    setAntecedentSearchType(value);
  };

  return (
      <SegmentedControl
        data={data}
        value={active}
        onChange={handleControlChange}
        color="#94ADA7" 
        radius="md"
        withItemsBorders={false}
        size="xs"
        transitionDuration={500}
        transitionTimingFunction="linear"
        fullWidth={true}
      />
  
  );
};
