import { jsPDF } from "jspdf";
import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "../Store";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { IconPrinter } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { Group, Button } from "@mantine/core";

const GenerateOrdonnance = ({type, data, dateType}) => {
  const ordonnanceStoreData = useStore((state) => state.ordonnanceData);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  let ordonnanceData
  if (type==='duplicata'){
    ordonnanceData = data
   
  }
  else  if (type==='ordonnance'){
    ordonnanceData = ordonnanceStoreData
  }

  const FormatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy", { locale: fr });
  };

 const [date, setDate] =useState(new Date)
 useEffect(() => {
  if (type === 'duplicata') {
    setDate(new Date(dateType));
  }
}, [type, dateType]);

  const generatePDF = useCallback(() => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;
    let yPos = 50;
    const lineSpacing = 5;
    const itemSpacing = 10;
    const headerSpacing = 15;
    const footerHeight = 10;
    const textHeight = 6;

    // Fonction pour ajouter l'en-tête
    const addHeader = () => {
      doc.setFontSize(16);
      doc.setFont("Optima", "bold");
      doc.text("Dr. Leon PACCABLE", 15, 15, { align: "left" });
      doc.setFont("Optima", "bolditalic");
      doc.setFontSize(12);
      doc.text("Spécialiste Médecine Générale", 15, 20, { align: "left" });
      doc.setFont("Optima", "normal");
      doc.setFontSize(11);
      doc.text("Ancien Interne des Hôpitaux de Bordeaux", 15, 25, {
        align: "left",
      });
      doc.text("123 Rue de l'Hôpital, 75001 Paris", 15, 30, { align: "left" });
      doc.text("Tel: 0576543234", 15, 35, { align: "left" });
      doc.setFont("Optima", "bold");
      doc.text("Rendez-vous sur Doctolib.fr", 15, 40, { align: "left" });
      doc.setFont("Times", "italic");
      yPos += 5;
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Rpps.png",
        "PNG",
        pageWidth / 2 - 10,
        15,
        40,
        23
      );
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Adeli.png",
        "PNG",
        pageWidth - 60,
        15,
        40,
        23
      );
    };

    // Fonction pour ajouter le pied de page
    const addFooter = () => {
      doc.setFontSize(10);
      doc.text(
        "Page " + doc.internal.getNumberOfPages(),
        pageWidth / 2,
        pageHeight - 10,
        { align: "center" }
      );

      doc.addImage(
        "https://www.iavicenne.fr/static/img/qr_code.png",
        "PNG",
        pageWidth - 25,
        pageHeight - 25,
        20,
        20
      );
    };

    // Fonction pour ajouter une nouvelle page
    const addNewPage = () => {
      doc.addPage();
      addHeader();
      yPos = 50;
    };

    // Fonction pour ajouter le nom du patient et la date
    const addPatientNameAndDate = () => {
      doc.setFont("Times", "normal");
      doc.setFontSize(12);
      addText(`Fait le ${FormatDate(date)}`, margin);
      if (type==='duplicata'){
        doc.setFont("Times", "bold");
        doc.setFontSize(27);
        doc.setTextColor('red')
        doc.text("DUPLICATA", 110, 50, {
          align: "left",
        });
        doc.setTextColor('black')
      }
      yPos += 5;

      const patientName = `Monsieur ${selectedPatientInfo.NomNaissance} ${selectedPatientInfo.ListePrenom}`;
      const patientNameX = pageWidth / 2;

      doc.setFont("Times", "bold");
      doc.setFontSize(15);
      doc.text(patientName, patientNameX, yPos, { align: "center" });

      // Ajouter un soulignement sous le nom du patient
      const textWidth = doc.getTextWidth(patientName);
      doc.line(
        patientNameX - textWidth / 2,
        yPos + 1,
        patientNameX + textWidth / 2,
        yPos + 1
      ); // +2 pour positionner la ligne juste en dessous du texte
      yPos += 10;
    };

    // Fonction pour ajouter du texte avec gestion automatique des retours à la ligne et nouvelles pages
    const addText = (
      text,
      x,
      fontSize = 12,
      maxWidth = pageWidth - 2 * margin
    ) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (yPos + textHeight > pageHeight - footerHeight - itemSpacing) {
          addNewPage();
          yPos += 5;
          addPatientNameAndDate();
          yPos += 5;
        }
        doc.text(line, x, yPos);
        yPos += textHeight;
      });
    };
    // Ajout de l'en-tête
    addHeader();

    // Ajout du nom du patient et de la date en haut de la première page
    addPatientNameAndDate();

    // Séparation des données de l'ordonnance en deux catégories
    const tttAldTrue = ordonnanceData.filter((item) => item.tttAld === true);
    const tttAldFalse = ordonnanceData.filter((item) => item.tttAld === false);

    let index = 1; // Initialisation de l'index global

    // Ajout des données tttAld === true avec les séparateurs et le texte
    if (tttAldTrue.length > 0) {
      doc.setFontSize(10);
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.text(
        "Prescriptions relatives au traitement de l'affection de longue durée reconnue (liste ou hors liste)",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(AFFECTIONS EXONERANTE)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;

      tttAldTrue.forEach((prescription) => {
        // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
        if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
          addNewPage();
          yPos += 5;
          addPatientNameAndDate();
          yPos += 5;
        }

        doc.setFont("Times", "bold");
        doc.setFontSize(12);
        addText(
          `${index}. ${prescription.dci} (${prescription.nom_commercial})`,
          margin,
          12,
          pageWidth - 2 * margin
        );
        doc.setFont("Times", "italic");
        doc.setFontSize(11);
        addText(prescription.commentaire, margin, 12, pageWidth - 2 * margin);
        yPos += 5; // Espacement
        index++; // Incrémentation de l'index global
      });

      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text(
        "Prescriptions SANS RAPPORT avec l'affection de longue durée",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(MALADIES INTERCURRENTES)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;
    }
    if (tttAldTrue.length === 0) {
      yPos += 5;
    }
    // Ajout des données tttAld === false
    tttAldFalse.forEach((prescription) => {
      // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
      if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
        addNewPage();
        yPos += 5;
        addPatientNameAndDate();
        yPos += 5;
      }
      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      addText(
        `${index}. ${prescription.dci} (${prescription.nom_commercial})`,
        margin,
        12,
        pageWidth - 2 * margin
      );
      doc.setFont("Times", "italic");
      doc.setFontSize(11);
      addText(prescription.commentaire, margin, 12, pageWidth - 2 * margin);
      yPos += 5; // Espacement
      index++; // Incrémentation de l'index global
    });

    // Ajout du pied de page sur toutes les pages
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addFooter();
    }

    // Générer le blob et ouvrir dans un nouvel onglet
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
    // doc.autoPrint();
  }, [ordonnanceData, selectedPatientInfo, date, type]);

  return (
    <Group>
     {type === 'ordonnance' &&(
         <DateInput
         variant="filled"
         valueFormat="DD/MM/YYYY"
         size="xs"
         radius="md"
         placeholder="Input placeholder"
         defaultValue={new Date()}
         value={date}
         onChange={setDate}
       />

      )}
      <Button
        className="printButton"
        variant="outline"
        size="xs"
        color="#94ADA7"
        radius="md"
        style={{ boxShadow: "0 2px 5px #bebebe" }}
        onClick={generatePDF}
      >
        <IconPrinter color="#94ADA7" size={19} />
      </Button>
    </Group>
  );
};

export default GenerateOrdonnance;
