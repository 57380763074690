import React, { useEffect } from "react";
import {
  Timeline,
  Text,
  Card,
  Title,
  Group,
  ScrollArea,
  Divider,
  Stack,
  Button,
} from "@mantine/core";
import {
  IconCircleCheck,
  IconHeartbeat,
  IconThermometer,
  IconWeight,
  IconStethoscope,
  IconPills,
  IconDroplet,
  IconSpray,
  IconRadioactive,
  IconMail,
  IconBandage,
  IconLetterA,
  IconFile,
} from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { RenewMedication } from "./AddDocumentOrdonnance";
import { RenewBiologie } from "./AddDocumentBiologie";
import { RenewRadiologie } from "./AddDocumentRadiologie";
import { RenewDispositifMedical } from "./AddDocumentDispositifMedical";
import { RenewOrdonnanceLibre } from "./AddDocumentOrdoLibre";

// import { GenerateOrdonnanceDuplicata } from "./printOrdonnanceDuplicata";
import { ShowOldOrdonnanceLibre } from "./printPrescLibre";
import GenerateOrdonnance from "./printOrdonnance";
import GenerateOrdonnanceBiologie from "./printBiologie";
import GenerateOrdonnanceRadiologie from "./printRadiologie";
import GenerateOrdonnanceDispositifMedical from "./printDispositifMedical";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export function HistoriqueTimeline() {
  const { historiqueData, setHistoriqueData, currentSessionInfo } = useStore();
  const showEncouterObjects = useStore((state) => state.showEncouterObjects);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "'le' dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
  };

  useEffect(() => {
    async function fetchEncounters() {
      try {
        const response = await client.get(
          `api/encounters/?practitioner=${currentSessionInfo.practitioner}&subject=${currentSessionInfo.subject}&status=archived`
        );
        setHistoriqueData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Failed to fetch encounters:", error);
      }
    }

    if (currentSessionInfo.practitioner && currentSessionInfo.subject) {
      fetchEncounters();
    }
  }, [
    currentSessionInfo.practitioner,
    currentSessionInfo.subject,
    setHistoriqueData,
  ]);

  const extractText = (encounterData) => {
    if (
      encounterData.ordonnanceLibre &&
      encounterData.ordonnanceLibre.editorState
    ) {
      const root = encounterData.ordonnanceLibre.editorState.root;
      if (root && Array.isArray(root.children)) {
        return root.children
          .map((paragraph) => {
            if (paragraph.children && Array.isArray(paragraph.children)) {
              return paragraph.children.map((child) => child.text).join("");
            }
            return "";
          })
          .join("\n");
      }
    }
    return "";
  };

  return (
    <ScrollArea h={'82vh'} offsetScrollbars scrollHideDelay={100}>
      <Timeline
        active={historiqueData.length}
        bulletSize={24}
        lineWidth={2}
        color="#94ada7"
      >
        {historiqueData
          .slice()
          .sort(
            (a, b) =>
              new Date(b.effectiveDateTime) - new Date(a.effectiveDateTime)
          )
          .map((encounter, index) => (
            <Timeline.Item
              key={encounter.effectiveDateTime || index}
              // bullet={<IconCircleCheck size={12} />}
            >
              <Group style={{ marginBottom: 10 }}>
                <Title order={5}>
                  {formatDate(encounter.effectiveDateTime)}
                </Title>
                {encounter.encounterData.observationMedicale?.observationContent
                  ?.motif && (
                  <Text tt="capitalize" fs="italic" fw={500} size="sm">
                    &nbsp;&nbsp;
                    {
                      encounter.encounterData.observationMedicale
                        .observationContent?.motif
                    }
                  </Text>
                )}
              </Group>
              <Stack gap="xs">
                {encounter.encounterData.observationMedicale &&
                  Object.keys(encounter.encounterData.observationMedicale)
                    .length > 0 &&
                  Object.values(
                    encounter.encounterData.observationMedicale
                      .observationContent
                  ).some((value) => value !== "") && (
                    <Card
                      className="TimelineCard"
                      shadow="lg"
                      radius="lg"
                      p="md"
                      withBorder={true}
                    >
                      <Group justify="space-between">
                        <Group gap="xs">
                          <IconStethoscope color="#94ADA7" />
                          <Title order={5} c="#94ADA7">
                            Observation
                          </Title>
                        </Group>

                        <Group justify="flex-end" gap="md">
                          {encounter.encounterData.observationMedicale
                            .observationContent.poids && (
                            <>
                              <Group gap="xs">
                                <IconWeight color="#94ADA7" />

                                <Text size="sm">
                                  {
                                    encounter.encounterData.observationMedicale
                                      .observationContent.poids
                                  }{" "}
                                  Kg
                                </Text>
                              </Group>
                            </>
                          )}
                          {encounter.encounterData.observationMedicale
                            .observationContent.temperature && (
                            <>
                              <Group gap="xs">
                                <IconThermometer color="#94ADA7" />

                                <Text size="sm">
                                  {
                                    encounter.encounterData.observationMedicale
                                      .observationContent.temperature
                                  }{" "}
                                  °C
                                </Text>
                              </Group>
                            </>
                          )}

                          {encounter.encounterData.observationMedicale
                            .observationContent.pressionArterielle && (
                            <>
                              <Group gap="xs">
                                <IconHeartbeat color="#94ADA7" />
                                <Text size="sm">
                                  {
                                    encounter.encounterData.observationMedicale
                                      .observationContent.pressionArterielle
                                  }{" "}
                                  mmHg
                                </Text>
                              </Group>
                            </>
                          )}

                          {encounter.encounterData.observationMedicale
                            .observationContent.frequenceCardiaque && (
                            <>
                              <Group gap="xs">
                                <IconHeartbeat color="#94ADA7" />
                                <Text size="sm">
                                  {
                                    encounter.encounterData.observationMedicale
                                      .observationContent.frequenceCardiaque
                                  }{" "}
                                  Bpm
                                </Text>
                              </Group>
                            </>
                          )}

                          {encounter.encounterData.observationMedicale
                            .observationContent.spO2 && (
                            <>
                              <Group gap="xs">
                                <IconSpray color="#94ADA7" />
                                <Text size="sm">
                                  {
                                    encounter.encounterData.observationMedicale
                                      .observationContent.spO2
                                  }{" "}
                                  %
                                </Text>
                              </Group>
                            </>
                          )}
                        </Group>
                      </Group>
                      <Text className="TimelineCardText" size="sm" ta="left">
                        {encounter.encounterData.observationMedicale
                          .synthese && (
                          <>
                            <Divider
                              my="xs"
                              label={
                                <Text fw={600} size="sm" c="dark">
                                  Synthèse
                                </Text>
                              }
                              labelPosition="left"
                            ></Divider>

                            <Text style={{ marginLeft: 20, marginBottom: 20 }}>
                              {
                                encounter.encounterData.observationMedicale
                                  .synthese
                              }
                            </Text>
                          </>
                        )}

                        {encounter.encounterData.observationMedicale
                          .observationContent.symptomes && (
                          <>
                            <Divider
                              my="xs"
                              label={
                                <Text fw={600} size="xs">
                                  Symptômes
                                </Text>
                              }
                              labelPosition="left"
                            ></Divider>

                            <Text style={{ marginLeft: 20, marginBottom: 20 }}>
                              {
                                encounter.encounterData.observationMedicale
                                  .observationContent.symptomes
                              }
                            </Text>
                          </>
                        )}
                        {encounter.encounterData.observationMedicale
                          .observationContent.signes && (
                          <>
                            <Divider
                              my="xs"
                              label={
                                <Text fw={600} size="xs">
                                  Signes
                                </Text>
                              }
                              labelPosition="left"
                            ></Divider>

                            <Text style={{ marginLeft: 20, marginBottom: 20 }}>
                              {
                                encounter.encounterData.observationMedicale
                                  .observationContent.signes
                              }
                            </Text>
                          </>
                        )}
                        {encounter.encounterData.observationMedicale
                          .observationContent.biologie && (
                          <>
                            <Divider
                              my="xs"
                              label={
                                <Text fw={600} size="xs">
                                  Biologie
                                </Text>
                              }
                              labelPosition="left"
                            ></Divider>

                            <Text style={{ marginLeft: 20, marginBottom: 20 }}>
                              {
                                encounter.encounterData.observationMedicale
                                  .observationContent.biologie
                              }
                            </Text>
                          </>
                        )}
                        {encounter.encounterData.observationMedicale
                          .observationContent.radiologie && (
                          <>
                            <Divider
                              my="xs"
                              label={
                                <Text fw={600} size="xs">
                                  Radiologie
                                </Text>
                              }
                              labelPosition="left"
                            ></Divider>
                            <Text style={{ marginLeft: 20, marginBottom: 20 }}>
                              {
                                encounter.encounterData.observationMedicale
                                  .observationContent.radiologie
                              }
                            </Text>
                          </>
                        )}
                      </Text>
                    </Card>
                  )}
                {encounter.encounterData.ordonnanceMedicaments &&
                  Object.keys(encounter.encounterData.ordonnanceMedicaments)
                    .length > 0 && (
                    <Card
                      className="TimelineCard"
                      shadow="lg"
                      radius="lg"
                      p="md"
                      withBorder={true}
                    >
                      <Group
                        gap="xs"
                        style={{ marginBottom: 20 }}
                        justify="space-between"
                      >
                        <Title order={5} c="#94ADA7">
                          <IconPills
                            color="#94ADA7"
                            style={{ verticalAlign: "middle", marginRight: 10 }}
                          />
                          Médicaments
                        </Title>
                        <Group justify="flex-end">
                          <GenerateOrdonnance
                            type='duplicata'
                            data={encounter.encounterData.ordonnanceMedicaments}
                            dateType={encounter.effectiveDateTime}
                          />
                          {showEncouterObjects && (
                            <RenewMedication encounterId={encounter.id} />
                          )}
                        </Group>
                      </Group>
                      {encounter.encounterData.ordonnanceMedicaments.map(
                        (medicament, index) => (
                          <>
                            <Text
                              size="0.75rem"
                              ta="left"
                              fw={600}
                              style={{ marginLeft: 25 }}
                            >
                              {index + 1}- {medicament.nom_commercial} (
                              {medicament.dci})
                            </Text>
                            {!medicament.commentaire && (
                              <Text
                                style={{ marginLeft: 40, marginBottom: 10 }}
                              >
                                {" "}
                              </Text>
                            )}
                            {medicament.commentaire && (
                              <Text
                                size="sm"
                                fs="italic"
                                c="dimmed"
                                style={{ marginLeft: 40, marginBottom: 10 }}
                              >
                                {medicament.commentaire}
                              </Text>
                            )}
                          </>
                        )
                      )}
                    </Card>
                  )}
                {encounter.encounterData.ordonnanceBiologie &&
                  Object.keys(encounter.encounterData.ordonnanceBiologie)
                    .length > 0 && (
                    <Card
                      className="TimelineCard"
                      shadow="lg"
                      radius="lg"
                      p="md"
                      withBorder={true}
                    >
                      <Group
                        gap="xs"
                        style={{ marginBottom: 20 }}
                        justify="space-between"
                      >
                        <Title order={5} c="#94ADA7">
                          <IconDroplet
                            color="#94ADA7"
                            style={{ verticalAlign: "middle", marginRight: 10 }}
                          />
                          Biologie
                        </Title>
                        <Group>
                        <GenerateOrdonnanceBiologie type='duplicata' data={encounter.encounterData.ordonnanceBiologie} dateType={encounter.effectiveDateTime}/>
                        {showEncouterObjects && (
                          <RenewBiologie encounterId={encounter.id} />
                        )}
                        </Group>
                      </Group>
                      {encounter.encounterData.ordonnanceBiologie?.map?.(
                        (biologie, index) => (
                          <Text
                            size="0.75rem"
                            ta="left"
                            fw={600}
                            style={{ marginLeft: 25, marginBottom: 10 }}
                          >
                            {index + 1}- {biologie.ServiceContent[0].label}
                          </Text>
                        )
                      )}
                    </Card>
                  )}
                {encounter.encounterData.ordonnanceRadiologie &&
                  Object.keys(encounter.encounterData.ordonnanceRadiologie)
                    .length > 0 && (
                    <Card
                      className="TimelineCard"
                      shadow="lg"
                      radius="lg"
                      p="md"
                      withBorder={true}
                    >
                      <Group justify="space-between">
                      <Title order={5} c="#94ADA7">
                        <IconRadioactive
                          color="#94ADA7"
                          style={{ verticalAlign: "middle", marginRight: 10 }}
                        />
                        Radiologie
                      </Title>
                      <Group>
                      <GenerateOrdonnanceRadiologie type='duplicata' data={encounter.encounterData.ordonnanceRadiologie} dateType={encounter.effectiveDateTime} />
                      {showEncouterObjects && (
                      <RenewRadiologie encounterId={encounter.id}/>
                      )}
                      </Group>
                      </Group>
                      {encounter.encounterData.ordonnanceRadiologie.map(
                        (radiologie, index) => (
                          <>
                            {radiologie.ServiceContent.map(
                              (service, index_2) => (
                                <Group align="baseline">
                                  <Text
                                    key={index}
                                    fw={550}
                                    className="TimelineCardText"
                                    size="sm"
                                    ta="left"
                                  >
                                    {index + 1}- {service.radiologie.examen}
                                  </Text>
                                  <Text>{service.radiologie.maladie}</Text>
                                </Group>
                              )
                            )}
                          </>
                        )
                      )}
                    </Card>
                  )}
                {encounter.encounterData.ordonnanceDispositifMedical &&
                  Object.keys(
                    encounter.encounterData.ordonnanceDispositifMedical
                  ).length > 0 && (
                    <Card
                      className="TimelineCard"
                      shadow="lg"
                      radius="lg"
                      p="md"
                      withBorder={true}
                    >
                      <Group justify="space-between">
                      <Title order={5} c="#94ADA7">
                        <IconBandage
                          color="#94ADA7"
                          style={{ verticalAlign: "middle", marginRight: 10 }}
                        />
                        Dipositifs Médiccaux
                      </Title>
                      <Group>
                      <GenerateOrdonnanceDispositifMedical type='duplicata' data={encounter.encounterData.ordonnanceDispositifMedical} dateType={encounter.effectiveDateTime}/>
                      {showEncouterObjects && (
                      <RenewDispositifMedical encounterId={encounter.id}/>
                      )}
                      </Group>
                      </Group>
                      {encounter.encounterData.ordonnanceDispositifMedical.map(
                        (dm, index) => (
                          <>
                            {dm.ServiceContent.map((service, index_2) => (
                              <Group align="baseline">
                                <Text
                                  key={index}
                                  fw={550}
                                  className="TimelineCardText"
                                  size="sm"
                                  ta="left"
                                >
                                  {index + 1}-{" "}
                                  {service.label.denominationCommerciale}
                                </Text>
                                <Text>{service.label.denominationNotice}</Text>
                              </Group>
                            ))}
                          </>
                        )
                      )}
                    </Card>
                  )}
                {encounter.encounterData.ordonnanceLibre &&
                  Object.keys(encounter.encounterData.ordonnanceLibre).length >
                    0 && (
                    <Card
                      className="TimelineCard"
                      shadow="lg"
                      radius="lg"
                      p="md"
                      withBorder
                    >
                      <Stack>
                        <Group justify="space-between">
                          <Title order={5} c="#94ADA7">
                            <IconFile
                              color="#94ADA7"
                              style={{
                                verticalAlign: "middle",
                                marginRight: 10,
                              }}
                            />
                            Autres Prescriptions
                          </Title>
                          <Group>
                          <ShowOldOrdonnanceLibre
                            data={encounter.encounterData.ordonnanceLibre}
                          />
                             {showEncouterObjects && (
                          <RenewOrdonnanceLibre encounterId={encounter.id}/>
                             )}
                        </Group>
                        </Group>
                        <Text fw={700} size="sm" style={{marginLeft:15}}>
                          {extractText(encounter.encounterData)}
                        </Text>
                      </Stack>
                    </Card>
                  )}
                {encounter.encounterData.courrierMedical &&
                  encounter.encounterData.courrierMedical.id && (
                    <Card
                      className="TimelineCard"
                      shadow="lg"
                      radius="lg"
                      p="md"
                      withBorder={true}
                    >
                      {" "}
                      <Group justify="space-between">
                        <Title order={5} c="#94ADA7">
                          <IconMail
                            color="#94ADA7"
                            style={{ verticalAlign: "middle", marginRight: 10 }}
                          />
                          Courrier Médical{" "}
                        </Title>
                        <a
                          component="a"
                          href={`https://www.iavicenne.fr/api/documents/${encounter.encounterData.courrierMedical.id}/view-document/?documentId=${encounter.encounterData.courrierMedical.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            variant="light"
                            color="#94ADA7"
                            radius="md"
                            style={{ width: 233 }}
                          >
                            {" "}
                            Voir le document
                          </Button>
                        </a>
                      </Group>
                    </Card>
                  )}
              </Stack>
            </Timeline.Item>
          ))}
      </Timeline>
    </ScrollArea>
  );
}

// import React, { useEffect } from "react";
// import {
//   Timeline,
//   Text,
//   Card,
//   Title,
//   Group,
//   ScrollArea,
//   Divider,
//   Stack,
// } from "@mantine/core";
// import {
//   IconCircleCheck,
//   IconHeartbeat,
//   IconThermometer,
//   IconWeight,
//   IconStethoscope,
//   IconPills,
//   IconDroplet,
//   IconSpray,
//   IconRadioactive,
// } from "@tabler/icons-react";
// import axios, { AxiosInstance } from "axios";
// import { useStore } from "../Store";
// import { format } from "date-fns";
// import { fr } from "date-fns/locale";
// import { RenewMedication } from "../components/AddDocumentOrdonnance";
// import { RenewBiologie } from "./AddDocumentBiologie";

// const client: AxiosInstance = axios.create({
//   baseURL: "https://www.iavicenne.fr/",
// });

// axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.withCredentials = true;

// interface ObservationContent {
//   motif?: string;
//   poids?: string;
//   temperature?: string;
//   pressionArterielle?: string;
//   frequenceCardiaque?: string;
//   spO2?: string;
//   symptomes?: string;
//   signes?: string;
//   biologie?: string;
//   radiologie?: string;
// }

// interface ObservationMedicale {
//   observationContent?: ObservationContent;
//   synthese?: string;
// }

// interface Medicament {
//   nom_commercial: string;
//   dci: string;
//   commentaire?: string;
// }

// interface BiologieService {
//   label: string;
// }

// interface RadiologieService {
//   radiologie: {
//     examen: string;
//   };
// }

// interface OrdonnanceLibre {
//   ServiceContent: string;
// }

// interface EncounterData {
//   observationMedicale?: ObservationMedicale;
//   ordonnanceMedicaments?: Medicament[];
//   ordonnanceBiologie?: { ServiceContent: BiologieService[] }[];
//   ordonnanceRadiologie?: { ServiceContent: RadiologieService[] }[];
//   ordonnanceLibre?: OrdonnanceLibre[];
// }

// interface Encounter {
//   id: string;
//   effectiveDateTime: string;
//   encounterData: EncounterData;
// }

// export function HistoriqueTimeline(): JSX.Element {
//   const {
//     historiqueData,
//     setHistoriqueData,
//     currentSessionInfo,
//     showEncouterObjects,
//   } = useStore();

//   const formatDate = (dateString: string): string => {
//     const date = new Date(dateString);
//     return format(date, "'le' dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
//   };

//   useEffect(() => {
//     async function fetchEncounters() {
//       try {
//         const response = await client.get<Encounter[]>(
//           `api/encounters/?practitioner=${currentSessionInfo.practitioner}&subject=${currentSessionInfo.subject}&status=archived`
//         );
//         setHistoriqueData(response.data);
//         console.log(response.data);
//       } catch (error) {
//         console.error("Failed to fetch encounters:", error);
//       }
//     }

//     if (currentSessionInfo.practitioner && currentSessionInfo.subject) {
//       fetchEncounters();
//     }
//   }, [
//     currentSessionInfo.practitioner,
//     currentSessionInfo.subject,
//     setHistoriqueData,
//   ]);

//   return (
//     <ScrollArea h={850} offsetScrollbars scrollHideDelay={100}>
//       <Timeline
//         active={historiqueData.length}
//         bulletSize={24}
//         lineWidth={2}
//         color="#94ada7"
//       >
//         {historiqueData
//           .slice()
//           .sort(
//             (a: Encounter, b: Encounter) =>
//               new Date(b.effectiveDateTime).getTime() -
//               new Date(a.effectiveDateTime).getTime()
//           )
//           .map((encounter: Encounter, index: number) => (
//             <Timeline.Item key={encounter.effectiveDateTime || index}>
//               <Group style={{ marginBottom: 20 }}>
//                 <Title order={5}>
//                   {formatDate(encounter.effectiveDateTime)}
//                 </Title>
//                 {encounter.encounterData.observationMedicale?.observationContent
//                   ?.motif && (
//                   <Text tt="capitalize" fs="italic" fw={500} size="sm">
//                     &nbsp;&nbsp;
//                     {
//                       encounter.encounterData.observationMedicale
//                         .observationContent.motif
//                     }
//                   </Text>
//                 )}
//               </Group>
//               <Stack gap="xs">
//                 {encounter.encounterData.observationMedicale &&
//                   Object.keys(encounter.encounterData.observationMedicale)
//                     .length > 0 &&
//                   Object.values(
//                     encounter.encounterData.observationMedicale
//                       .observationContent || {}
//                   ).some((value) => value !== "") && (
//                     <Card
//                       className="TimelineCard"
//                       shadow="lg"
//                       radius="lg"
//                       p="md"
//                       withBorder={true}
//                     >
//                       <Group justify="space-between">
//                         <Group gap="xs">
//                           <IconStethoscope color="#94ADA7" />
//                           <Title order={5} c="#94ADA7">
//                             Observation
//                           </Title>
//                         </Group>

//                         <Group justify="flex-end" gap="md">
//                           {encounter.encounterData.observationMedicale
//                             .observationContent?.poids && (
//                             <Group gap="xs">
//                               <IconWeight color="#94ADA7" />
//                               <Text size="sm">
//                                 {
//                                   encounter.encounterData.observationMedicale
//                                     .observationContent.poids
//                                 }{" "}
//                                 Kg
//                               </Text>
//                             </Group>
//                           )}
//                           {encounter.encounterData.observationMedicale
//                             .observationContent?.temperature && (
//                             <Group gap="xs">
//                               <IconThermometer color="#94ADA7" />
//                               <Text size="sm">
//                                 {
//                                   encounter.encounterData.observationMedicale
//                                     .observationContent.temperature
//                                 }{" "}
//                                 °C
//                               </Text>
//                             </Group>
//                           )}
//                           {encounter.encounterData.observationMedicale
//                             .observationContent?.pressionArterielle && (
//                             <Group gap="xs">
//                               <IconHeartbeat color="#94ADA7" />
//                               <Text size="sm">
//                                 {
//                                   encounter.encounterData.observationMedicale
//                                     .observationContent.pressionArterielle
//                                 }{" "}
//                                 mmHg
//                               </Text>
//                             </Group>
//                           )}
//                           {encounter.encounterData.observationMedicale
//                             .observationContent?.frequenceCardiaque && (
//                             <Group gap="xs">
//                               <IconHeartbeat color="#94ADA7" />
//                               <Text size="sm">
//                                 {
//                                   encounter.encounterData.observationMedicale
//                                     .observationContent.frequenceCardiaque
//                                 }{" "}
//                                 Bpm
//                               </Text>
//                             </Group>
//                           )}
//                           {encounter.encounterData.observationMedicale
//                             .observationContent?.spO2 && (
//                             <Group gap="xs">
//                               <IconSpray color="#94ADA7" />
//                               <Text size="sm">
//                                 {
//                                   encounter.encounterData.observationMedicale
//                                     .observationContent.spO2
//                                 }{" "}
//                                 %
//                               </Text>
//                             </Group>
//                           )}
//                         </Group>
//                       </Group>
//                       <Text className="TimelineCardText" size="sm" ta="left">
//                         {encounter.encounterData.observationMedicale
//                           .synthese && (
//                           <>
//                             <Divider
//                               my="xs"
//                               label={
//                                 <Text fw={600} size="sm" c="dark">
//                                   Synthèse
//                                 </Text>
//                               }
//                               labelPosition="left"
//                             />
//                             <Text style={{ marginLeft: 20, marginBottom: 20 }}>
//                               {
//                                 encounter.encounterData.observationMedicale
//                                   .synthese
//                               }
//                             </Text>
//                           </>
//                         )}
//                         {encounter.encounterData.observationMedicale
//                           .observationContent?.symptomes && (
//                           <>
//                             <Divider
//                               my="xs"
//                               label={
//                                 <Text fw={600} size="xs">
//                                   Symptômes
//                                 </Text>
//                               }
//                               labelPosition="left"
//                             />
//                             <Text style={{ marginLeft: 20, marginBottom: 20 }}>
//                               {
//                                 encounter.encounterData.observationMedicale
//                                   .observationContent.symptomes
//                               }
//                             </Text>
//                           </>
//                         )}
//                         {encounter.encounterData.observationMedicale
//                           .observationContent?.signes && (
//                           <>
//                             <Divider
//                               my="xs"
//                               label={
//                                 <Text fw={600} size="xs">
//                                   Signes
//                                 </Text>
//                               }
//                               labelPosition="left"
//                             />
//                             <Text style={{ marginLeft: 20, marginBottom: 20 }}>
//                               {
//                                 encounter.encounterData.observationMedicale
//                                   .observationContent.signes
//                               }
//                             </Text>
//                           </>
//                         )}
//                         {encounter.encounterData.observationMedicale
//                           .observationContent?.biologie && (
//                           <>
//                             <Divider
//                               my="xs"
//                               label={
//                                 <Text fw={600} size="xs">
//                                   Biologie
//                                 </Text>
//                               }
//                               labelPosition="left"
//                             />
//                             <Text style={{ marginLeft: 20, marginBottom: 20 }}>
//                               {
//                                 encounter.encounterData.observationMedicale
//                                   .observationContent.biologie
//                               }
//                             </Text>
//                           </>
//                         )}
//                         {encounter.encounterData.observationMedicale
//                           .observationContent?.radiologie && (
//                           <>
//                             <Divider
//                               my="xs"
//                               label={
//                                 <Text fw={600} size="xs">
//                                   Radiologie
//                                 </Text>
//                               }
//                               labelPosition="left"
//                             />
//                             <Text style={{ marginLeft: 20, marginBottom: 20 }}>
//                               {
//                                 encounter.encounterData.observationMedicale
//                                   .observationContent.radiologie
//                               }
//                             </Text>
//                           </>
//                         )}
//                       </Text>
//                     </Card>
//                   )}
//                 {encounter.encounterData.ordonnanceMedicaments &&
//                   encounter.encounterData.ordonnanceMedicaments.length > 0 && (
//                     <Card
//                       className="TimelineCard"
//                       shadow="lg"
//                       radius="lg"
//                       p="md"
//                       withBorder={true}
//                     >
//                       <Group
//                         gap="xs"
//                         style={{ marginBottom: 20 }}
//                         justify="space-between"
//                       >
//                         <Title order={5} c="#94ADA7">
//                           <IconPills
//                             color="#94ADA7"
//                             style={{ verticalAlign: "middle", marginRight: 10 }}
//                           />
//                           Médicaments
//                         </Title>
//                         {showEncouterObjects && (
//                           <RenewMedication encounterId={encounter.id} />
//                         )}
//                       </Group>
//                       {encounter.encounterData.ordonnanceMedicaments.map(
//                         (medicament, index) => (
//                           <React.Fragment key={index}>
//                             <Text
//                               size="0.75rem"
//                               ta="left"
//                               fw={600}
//                               style={{ marginLeft: 25 }}
//                             >
//                               {index + 1}- {medicament.nom_commercial} (
//                               {medicament.dci})
//                             </Text>
//                             {!medicament.commentaire ? (
//                               <Text
//                                 style={{ marginLeft: 40, marginBottom: 10 }}
//                               >
//                                 {" "}
//                               </Text>
//                             ) : (
//                               <Text
//                                 size="sm"
//                                 fs="italic"
//                                 c="dimmed"
//                                 style={{ marginLeft: 40, marginBottom: 10 }}
//                               >
//                                 {medicament.commentaire}
//                               </Text>
//                             )}
//                           </React.Fragment>
//                         )
//                       )}
//                     </Card>
//                   )}
//                 {encounter.encounterData.ordonnanceBiologie &&
//                   encounter.encounterData.ordonnanceBiologie.length > 0 && (
//                     <Card
//                       className="TimelineCard"
//                       shadow="lg"
//                       radius="lg"
//                       p="md"
//                       withBorder={true}
//                     >
//                       <Group
//                         gap="xs"
//                         style={{ marginBottom: 20 }}
//                         justify="space-between"
//                       >
//                         <Title order={5} c="#94ADA7">
//                           <IconDroplet
//                             color="#94ADA7"
//                             style={{ verticalAlign: "middle", marginRight: 10 }}
//                           />
//                           Biologie
//                         </Title>
//                         {showEncouterObjects && (
//                           <RenewBiologie encounterId={encounter.id} />
//                         )}
//                       </Group>
//                       {encounter.encounterData.ordonnanceBiologie.map(
//                         (biologie, index) => (
//                           <Text
//                             key={index}
//                             size="0.75rem"
//                             ta="left"
//                             fw={600}
//                             style={{ marginLeft: 25, marginBottom: 10 }}
//                           >
//                             {index + 1}- {biologie.ServiceContent[0].label}
//                           </Text>
//                         )
//                       )}
//                     </Card>
//                   )}
//                 {encounter.encounterData.ordonnanceRadiologie &&
//                   encounter.encounterData.ordonnanceRadiologie.length > 0 && (
//                     <Card
//                       className="TimelineCard"
//                       shadow="lg"
//                       radius="lg"
//                       p="md"
//                       withBorder={true}
//                     >
//                       <Title order={5} c="#94ADA7">
//                         <IconRadioactive
//                           color="#94ADA7"
//                           style={{ verticalAlign: "middle", marginRight: 10 }}
//                         />
//                         Radiologie
//                       </Title>
//                       {encounter.encounterData.ordonnanceRadiologie.map(
//                         (radiologie, index) => (
//                           <React.Fragment key={index}>
//                             {radiologie.ServiceContent.map(
//                               (service, index_2) => (
//                                 <Text
//                                   key={index_2}
//                                   fw={550}
//                                   className="TimelineCardText"
//                                   size="sm"
//                                   ta="left"
//                                 >
//                                   {index + 1}- {service.radiologie.examen}
//                                 </Text>
//                               )
//                             )}
//                           </React.Fragment>
//                         )
//                       )}
//                     </Card>
//                   )}
//                 {encounter.encounterData.ordonnanceLibre &&
//                   encounter.encounterData.ordonnanceLibre.length > 0 && (
//                     <Card
//                       className="TimelineCard"
//                       shadow="lg"
//                       radius="lg"
//                       p="md"
//                       withBorder={true}
//                     >
//                       <Title order={5} c="#94ADA7">
//                         Autres
//                       </Title>
//                       {encounter.encounterData.ordonnanceLibre.map(
//                         (libre, index) => (
//                           <Text
//                             key={index}
//                             className="TimelineCardText"
//                             size="sm"
//                             ta="left"
//                           >
//                             {libre.ServiceContent}
//                           </Text>
//                         )
//                       )}
//                     </Card>
//                   )}
//               </Stack>
//             </Timeline.Item>
//           ))}
//       </Timeline>
//     </ScrollArea>
//   );
// }
