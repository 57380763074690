import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Image,
  Grid,
  Switch,
  Stack,
  Group,
  Text,
  Card,
  Title,
  SegmentedControl,
  NumberInput,
  rem,
  Textarea,
  Loader,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";


const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function ShowMedicament() {
  const {
    ordonnanceData,
    setOrdonnanceData,
    posologie,
    setPosologie,
    selectedMedicamentDetail,
    setSelectedMedicamentDetail,
    selectedPatientInfo,
    setSelectedPatientInfo,
    
    
  } = useStore();

  const [localMedicament, setLocalMedicament] = useState(
    selectedMedicamentDetail
  );

  // const getPosologie = async (updatedMedicament) => {
  //   // Vérifier si le médicament sélectionné contient déjà une clé 'Posologie'
  //   if (updatedMedicament.rcp && typeof updatedMedicament.rcp === "object") {
  //     setPosologie(updatedMedicament.rcp);
  //   } else {
  //     console.log("pas de rcp");
  //     console.log(ordonnanceData);

      // try {
      //   const response = await client.get(
      //     `api/cis-bdpm/?dci=&denomination=&cisCode=${updatedMedicament.ciscode}`
      //   );
      //   if (response.status === 200) {
      //     console.log(response.data.rcp);
      //     const posologieData = response.data.rcp;
      //     setPosologie(posologieData);

      //     // Mettre à jour selectedMedicamentDetail avec la nouvelle Posologie
      //     const updatedMedicamentDetail = {
      //       ...updatedMedicament,
      //       rcp: posologieData,
      //     };
      //     setSelectedMedicamentDetail(updatedMedicamentDetail);
      //     debouncedUpdateDatabase(updatedMedicamentDetail);

      //     // // Mettre à jour ordonnanceData
      //     // const updatedOrdonnanceData = ordonnanceData.map((medicament) =>
      //     //   medicament.id === updatedMedicament.id
      //     //     ? updatedMedicamentDetail
      //     //     : medicament
      //     // );
      //     // setOrdonnanceData(updatedOrdonnanceData);
      //   } else {
      //     console.log("error, couldn't get posologie");
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
  //   }
  // };

  useEffect(() => {
    setLocalMedicament(selectedMedicamentDetail);
    // setPosologie([]);
    // getPosologie(selectedMedicamentDetail);
  }, [selectedMedicamentDetail]);

  useEffect(() => {
    // Vérifier si le médicament sélectionné est présent dans MedicationStatement
    if (selectedPatientInfo && selectedPatientInfo.MedicationStatement) {
      const medicamentInMedicationStatement = selectedPatientInfo.MedicationStatement.find(
        (medication) => medication.medoc_id === selectedMedicamentDetail.medoc_id
      );
  
      if (medicamentInMedicationStatement) {
        setLocalMedicament((prevState) => ({
          ...prevState,
          tttFond: medicamentInMedicationStatement.tttFond,
        }));
      }
    }
  }, [selectedPatientInfo]);

  const updateDatabase = async (updatedMedicament) => {
    try {
      console.log(updatedMedicament);
  
      // Récupérer l'id du médicament dans la MedicationRequest
      const medicationRequest = ordonnanceData.find(
        (medication) => medication.medoc_id === updatedMedicament.medoc_id
      );
  
      if (medicationRequest) {
        const response = await client.patch(
          `api/medication-requests/${medicationRequest.id}/`,
          updatedMedicament
        );
        if (response.status === 200) {
          console.log("medication request updated");
        } else {
          console.log("error, couldn't update medication request");
        }
      } else {
        console.log("Médicament non trouvé dans la MedicationRequest");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedUpdateDatabase = useCallback(
    debounce(updateDatabase, 2000),
    []
  );

  const updateMedicament = useCallback(
    (key, value) => {
      const updatedMedicament = { ...localMedicament, [key]: value };
      setLocalMedicament(updatedMedicament);
      setSelectedMedicamentDetail(updatedMedicament);
  
      const updatedOrdonnanceData = ordonnanceData.map((medicament) =>
        medicament.id === updatedMedicament.id ? updatedMedicament : medicament
      );
  
      setOrdonnanceData(updatedOrdonnanceData);
  
      debouncedUpdateDatabase(updatedMedicament);
  
      // Appeler la fonction spécifique lorsque le switch 'tttFond' est modifié
      // if (key === "tttFond") {
      //   handleTttFondChange(value, updatedMedicament);
      // }
    },
    [
      localMedicament,
      ordonnanceData,
      setOrdonnanceData,
      setSelectedMedicamentDetail,
      debouncedUpdateDatabase,
    ]
  );
  
  const handleTttFondChange = async (updatedMedicament) => {
    try {
      let updatedFields = {};
        const hasDuplicate = selectedPatientInfo.MedicationStatement?.some(
          (medication) => medication.medoc_id === updatedMedicament.medoc_id
        );
  
        if (!hasDuplicate) {
          console.log(updatedMedicament);
          updatedFields = {
            MedicationStatement: [
              ...(selectedPatientInfo.MedicationStatement || []),
              { ...updatedMedicament, tttFond: true },
            ],
          };
        } else {
          notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Image
                  fit="contain"
                  src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                  alt="iAvicenne Logo"
                  width={25}
                  height={25}
                  style={{ margin: "0", display: "block" }}
                />
                <Text fw={700}>Médicament déjà ajouté</Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        }
      
      const response = await client.patch(
        `api/patients/${selectedPatientInfo.id}/`,
        updatedFields
      );
  
      if (response.status === 200) {
        setSelectedPatientInfo(response.data);
      } else {
        console.log("Error updating patient information");
      }
    } catch (error) {
      console.log(error);
    
  }
};

  const renderSwitch = (key, label) => (
    <Switch
      checked={localMedicament[key]}
      onChange={(event) => updateMedicament(key, event.currentTarget.checked)}
      color="teal"
      onLabel={label}
      offLabel={label}
      size="md"
      radius="md"
      thumbIcon={
        localMedicament[key] ? (
          <IconCheck
            style={{ width: rem(12), height: rem(12) }}
            color="teal"
            stroke={3}
          />
        ) : (
          <IconX
            style={{ width: rem(12), height: rem(12) }}
            color="red"
            stroke={3}
          />
        )
      }
      style={{ marginTop: "16" }}
    />
  );

  const renderInput = (key, label, type = "text") => (
    <Textarea
      value={localMedicament[key] || ""}
      onChange={(event) => updateMedicament(key, event.currentTarget.value)}
      placeholder={label}
      variant="filled"
      size="xs"
      radius="md"
      type={type}
    />
  );

  const renderNumberInput = (key, label) => (
    <NumberInput
      value={localMedicament[key] || 0}
      onChange={(value) => updateMedicament(key, value)}
      variant="filled"
      size="xs"
      radius="md"
      description={label}
      style={{ maxWidth: "95" }}
      allowNegative={false}
      allowDecimal={true}
      decimalScale={2}
      stepHoldDelay={500}
      stepHoldInterval={100}
      step={0.5}
    />
  );

  const renderSegmentedControl = (key, label, options) => (
    <SegmentedControl
      data={options}
      value={localMedicament[key] || 0}
      onChange={(value) => updateMedicament(key, value)}
      color="#94ADA7"
      size="xs"
      radius="md"
      transitionDuration={500}
      transitionTimingFunction="linear"
      withItemsBorders={false}
      style={{ marginTop: "16", minWidth: "140" }}
    />
  );
  return (
    <Stack>
      <Card radius="lg" shadow="lg" padding="lg" withBorder={true}>
        <Grid align="center">
          <Grid.Col span={1}>
            {" "}
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/molecule.png"
              alt="iAvicenne Logo"
              width={50}
              height={50}
              style={{ margin: "0", display: "block", verticalAlign: "middle" }}
            />
          </Grid.Col>
          <Grid.Col span={10}>
            {" "}
            <Title
              order={2}
              ta="left"
              style={{ marginTop: "10", marginBottom: "10" }}
            >
              {/* <IconPillFilled
            color="#94ADA7"
            size={40}
            style={{ verticalAlign: "middle", marginRight: "15", marginLeft: "15" }}
          /> */}
              {selectedMedicamentDetail.dci}
              <Text ta="left" size="xs" fs="italic">
                {" "}
                {selectedMedicamentDetail.nom_commercial} (
                {selectedMedicamentDetail.commercialisation})
              </Text>
            </Title>
          </Grid.Col>
        </Grid>

        <Group>
          <Text ta="center" size="xs" fs="italic">
            {/* <IconPills color="gray" size={12} style={{ marginRight: "5" }} /> */}
          </Text>
        </Group>
      </Card>
      <Card radius="lg" shadow="lg" padding="lg" withBorder={true}>
        <Stack>
          <Group grow>
            {renderNumberInput("matin", "Matin")}
            {renderNumberInput("midi", "Midi")}
            {renderNumberInput("soir", "Soir")}
            {renderNumberInput("coucher", "Coucher")}
            {renderNumberInput("nombre_periode", "Pendant")}
            {renderSegmentedControl("periode", "Période", [
              { value: "jour", label: "Jour" },
              { value: "semaine", label: "Semaine" },
              { value: "mois", label: "Mois" },
            ])}
          </Group>
          {renderInput("commentaire", "Prescription Manuelle ou Commentaire")}
          <Group justify="flex-start">
            {renderSwitch("tttAld", "ALD")}
            {/* {renderSwitch("tttFond", "FOND")} */}
            {renderSwitch("nsMTE", "MTE")}
            {renderSwitch("nsEFG", "EFG")}
            {renderSwitch("nsCIF", "CIF")}

            {renderNumberInput("renouveler", "Renouveler")}
          </Group>
          <Button variant="light" color="#94ADA7" radius="md" onClick={() => handleTttFondChange(selectedMedicamentDetail)}>Ajouter au ttt de fond</Button>

        </Stack>
      </Card>
      {posologie && Object.keys(posologie).length > 0 && (
        <Card radius="lg" shadow="lg" padding="lg" withBorder={true}>
          <Stack>
            {/* Indications */}
            {posologie.References &&
              posologie.References.map((ref, index) => (
                <Text size="xs" ta="right" fw={700} key={index}>
                  {ref.Date}, {ref.Source}
                </Text>
              ))}
            {posologie.Indications &&
              posologie.Indications.map((indication, index) => (
                <div key={index}>
                  {indication.Contexte &&
                    indication.Contexte.map((ctx, ctxIndex) => (
                      <>
                        <Title order={5} ta="left" style={{ marginBottom: 0 }}>
                          {ctx.Description}
                        </Title>

                        <Text
                          onClick={() =>
                            updateMedicament("commentaire", ctx.Posologie)
                          }
                          size="sm"
                          ta="left"
                          style={{
                            margin: 5,
                            marginLeft: 25,
                            marginBottom: 20,
                            cursor: "pointer",
                          }}
                        >
                          {ctx.Posologie}
                        </Text>
                      </>
                    ))}
                </div>
              ))}

            {/* Contre-indications */}

            {/* {posologie['Contre-indications'] && (
      <ul>
        {posologie['Contre-indications'].map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    )} */}

            {/* Précautions */}
            {/*   
    {posologie.Precautions && (
      <ul>
        {posologie.Precautions.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    )} */}

            {/* Références */}
          </Stack>
        </Card>
      )}
    </Stack>
  );
}
