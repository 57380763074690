import React, { useState, useEffect } from 'react';
import { Text,Button,Tooltip } from "@mantine/core";
import { IconFile,IconFileCertificate,IconFileFilled,IconFileXFilled,IconPlus,} from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { set } from 'date-fns';


const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export const RenewOrdonnanceLibre = ({ encounterId }) => {
  const setNewEditorState = useStore((state) => state.setNewEditorState);
  const { setActive, setActiveTab } = useStore();
  const [loading, setLoading] = useState(false);

 
  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/encounters/${encounterId}/`);

      if (response.status === 200) {
        const ordonnance = response.data.encounterData.ordonnanceLibre;
        const NewEditoState ={
          trigger:true, 
          root: ordonnance.editorState,
        }
        setNewEditorState(NewEditoState)
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
    label={
    
        <Text size="xs">
         Renouveler l'Ordonnance
        </Text>
      
    }
    color="#94ADA7"
    withArrow
    multiline
  
    position="top"
    radius="md"
    arrowOffset={25}
    arrowSize={8}
    transitionProps={{ transition: "pop", duration: 500 }}
    openDelay={1000}
  >
    <Button 
    className='renouvelerButton'
      color="#94ADA7"
      radius="md"
      size="xs"
      onClick={() => {
       
        renouvelerOrdo();
        setActive("autres")
        setActiveTab("autres")
      }} 
      loading={loading} 
      disabled={loading}
    >
      <IconPlus size={16}/>
      <IconFileFilled size={18}/>
    
    </Button>
    </Tooltip>
   
 
  );
};

