import React, { useEffect, useState, useCallback } from "react";
import { Select, Loader } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });


axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;







function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
async function getBiologies(query = "") {
  const response = await client.get(`api/liste-bio/search/?bio=${query}`);
  if (response.status !== 200) throw new Error("Network response was not ok");
  return response.data;
}

function SearchBiologies() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const biologieData = useStore((state) => state.biologieData);
  const setBiologieData = useStore((state) => state.setBiologieData);

  const debouncedGetBiologies = useCallback(
    debounce(async (query) => {
      try {
        const Biologies = await getBiologies(query);

        const formattedData = Biologies.liste_bio_results.map((Biologie) => ({
          value: String(Biologie.id),
          label: `${Biologie.biologie}`,
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Failed to fetch Biologies:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      debouncedGetBiologies(query);
    } else {
      setData([]);
      setQuery("");
    }
  }, [query, debouncedGetBiologies]);

  // Choisir une biologie et l'enregitrer dasn l'ordonnance en cours
  const handleBiologieClick = async (value) => {
    const BiologieData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      categoryContent: String("Biologie").trim(),
      ServiceId: parseInt(value.value, 10),
      ServiceContent: [{ label: value.label, tttAld: false }],
    };
    console.log(BiologieData);

    try {
      const isBiologieIdPresent = biologieData.some(
        (item) => item.serviceId === parseInt(value.value, 10)
      );
      if (isBiologieIdPresent) {
        // Si l'entrée existe, mettre à jour
        // const existingId = response_1.data[0].id;
        console.log("cette biologie à déja été prescrite");
      } else {
        // Si l'entrée n'existe pas, créer une nouvelle entrée
        const updatedBiologie = [...biologieData, BiologieData];
        setBiologieData(updatedBiologie);
        const response = await client.post(
          "api/service-request/",
          BiologieData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 201) {
          const newBiologieData = {
            ...BiologieData,
            id: response.data.id, // Utiliser l'ID retourné par l'API
          };
          setBiologieData([...biologieData, newBiologieData]);
          console.log("biologie mise à jour");
        } else {
          console.error("Failed to save new biologie:", response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Select
      searchable
      clearable
      placeholder="Rechercher une Biologie"
      data={data}
      allowDeselect={false}
      maxDropdownHeight={400}
      searchValue={query}
      value={selectedValue}
      onSearchChange={setQuery}
      onChange={(value, Biologie) => {
        if (Biologie) {
          handleBiologieClick(Biologie);
          setSelectedValue(null);
          setQuery("");
          setData([]);
        }
      }}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "lg",
        radius: "lg",
      }}
      style={{ width: "100%", marginBottom: 20 }}
      leftSection={<IconSearch size="1rem" style={{ display: "block" }} />}
      rightSection={loading && <Loader size="sm" />}
    />
  );
}

export default SearchBiologies;
