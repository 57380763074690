import { useStore } from "../Store";
import { Button, Image, Text, Group, Loader, Card, Stack, Title, List } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import {
  IconCheck,
  IconDropletFilled,
  IconRadioactiveFilled,
} from "@tabler/icons-react";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export function ExamensComplementairesButton({ maladie }) {
  const setExamensComplementaires = useStore(
    (state) => state.setExamensComplementaires
  );
  const setCurrentNotification = useStore(
    (state) => state.setCurrentNotification
  );

  const handleClick = async () => {
    try {

      const notificationId = notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>Examens Complementaires</Text>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Group>
        ),
        opacity: 0.7,
        autoClose: false,
        withCloseButton: true,


      });
      setCurrentNotification(notificationId);

      const response = await client
.get(`api/examens/?maladie=${maladie}`);
      if (response.status === 200) {
        notifications.update({
          id: notificationId,
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Recherche Terminée</Text>
              <IconCheck
                style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
              />
            </Group>
          ),
          opacity: 0.7,
          autoClose: 1000,
          withCloseButton: true,


        });
        setExamensComplementaires(response.data);
      }
    } catch (error) {
      console.error("Error fetching examens complementaires:", error);
      notifications.show({
        title: "Erreur",
        message: "Impossible de récupérer les examens complémentaires",
        color: "red",
      });
    }
  };

  return (
    <Button
      onClick={handleClick}
      className="propExamenButton"
      variant="outline"
      size="xs"
      color="#94ADA7"
      radius="lg"
      style={{ boxShadow: "0 2px 5px #bebebe" }} 
    >
      <IconDropletFilled color="#94ADA7" size={10} />
      <IconRadioactiveFilled color="#94ADA7" size={10} />
    </Button>
  );
}

export function ShowExamensComplementaires() {
  const examensComplementaires = useStore(
    (state) => state.examensComplementaires
  );
  // console.log(examensComplementaires.examens);

  if (!examensComplementaires.examens) return null;

  return (
    <Stack>
      {examensComplementaires.examens.biologie && examensComplementaires.examens.biologie.length>0 &&(
        <Card shadow="lg" radius="lg" padding={20}>
          <Title size={22} ta="left" style={{marginBottom:"20"}}>Examens Biologiques</Title>
          {Object.entries(examensComplementaires.examens.biologie).map(
            ([key, value], index) => (
              <List withPadding={true} ta="left" size="sm">
                <List.Item key={index} style={{marginBottom:"5"}}>
                  <strong>{value.examen}</strong>: {value.anomalie}
                </List.Item>
              </List>
            )
          )}
        </Card>
      )}

      {examensComplementaires.examens.radiologie && examensComplementaires.examens.radiologie.length>0 &&(
        <Card shadow="lg" radius="lg" padding={20}>
          <Title size={22} ta="left" style={{marginBottom:"20"}}>Examens Radiologiques</Title>
          {Object.entries(examensComplementaires.examens.radiologie).map(
            ([key, value], index) => (
                <List withPadding={true} ta="left" size="md" key={index}>
               
                  <List.Item><strong>{value.titre}</strong></List.Item>
                  <List withPadding={true} ta="left" size="sm" key={index} style={{marginBottom:"10"}}>
                    <List.Item>Indication: {value.indication}</List.Item>
                    <List.Item  > Anomalie: {value.anomalie} </List.Item>
                  </List>
              </List>
            )
          )}
        </Card>
      )}
    </Stack>
  );
}
