import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  $getRoot,
  $createTextNode,
  $createParagraphNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import {
  $setBlocksType
} from '@lexical/selection';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list';
import {$createHeadingNode} from '@lexical/rich-text';
import {useCallback, useEffect, useRef, useState} from 'react';
import { useStore } from "../Store";
import { useDebouncedCallback } from '@mantine/hooks';
import { $createListNode, $createListItemNode } from '@lexical/list';




const LowPriority = 1;

function Divider() {
  return <div className="divider" />;
}

const fontSizes = [
  '8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px', '48px', '60px', '72px', '96px'
];

const fontFamilies = [
  'Arial', 'Helvetica','Calibri', 'Times New Roman', 'Courier', 'Verdana', 'Georgia', 'Palatino', 'Garamond', 'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 
];

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [fontSize, setFontSize] = useState('12px');
  const [fontFamily, setFontFamily] = useState('Colibri');
  const editorState = editor.getEditorState();
  const newEditorState =  useStore((state) => state.newEditorState);


  const stringifiedEditorState = JSON.stringify(
    editor.getEditorState().toJSON(),
  );
  

  useEffect(() => {
    if (newEditorState.trigger === true) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        newEditorState.root.root.children.forEach(node => {
          if (node.type === 'paragraph') {
            const paragraph = $createParagraphNode();
            node.children.forEach(child => {
              const text = $createTextNode(child.text);
              if (child.format) text.setFormat(child.format);
              paragraph.append(text);
            });
            root.append(paragraph);
          } else if (node.type === 'list') {
            const list = $createListNode(node.tag);
            node.children.forEach(item => {
              const listItem = $createListItemNode();
              item.children.forEach(child => {
                const text = $createTextNode(child.text);
                if (child.format) text.setFormat(child.format);
                listItem.append(text);
              });
              list.append(listItem);
            });
            root.append(list);
          }
        });
      });
    }
  }, [newEditorState, editor]);
 

  

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      
    }
  }, []);


  const debouncedSetEditorState = useDebouncedCallback(
    (state) => {
      useStore.getState().setEditorState(JSON.stringify(state));
    },
    300, // 300ms de délai, ajustez selon vos besoins
    []  // Les dépendances vides signifient que cette fonction sera créée une seule fois
  );

  useEffect(() => {
    const unsubscribe = editor.registerUpdateListener(({ editorState }) => {
      // Utiliser la fonction debounce
      debouncedSetEditorState(editorState);
     
    });
  
    // Nettoyer l'abonnement lors du démontage du composant
    return () => {
      unsubscribe();
      // Pas besoin d'appeler .cancel() avec useDebouncedCallback de Mantine
    };
  }, [editor, debouncedSetEditorState]);
  useEffect(() => {
  
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, $updateToolbar]);

  // const formatHeading = (headingSize) => {
  //   editor.update(() => {
  //     const selection = $getSelection();
  //     if ($isRangeSelection(selection)) {
  //       $setBlocksType(selection, () => $createHeadingNode(`h${headingSize}`));
  //     }
  //   });
  // };

  const changeFontSize = (e) => {
    const newFontSize = e.target.value;
    setFontSize(newFontSize);
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach(node => {
          if (node.getType() === 'text') {
            node.setStyle(`font-size: ${newFontSize};`);
          }
        });
      }
    });
  };

  const changeFontFamily = (e) => {
    const newFontFamily = e.target.value;
    setFontFamily(newFontFamily);
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach(node => {
          if (node.getType() === 'text') {
            node.setStyle(`font-family: ${newFontFamily};`);
          }
        });
      }
    });
  };
  return (
    <div className="toolbar" ref={toolbarRef}>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        aria-label="Undo">
        <i className="format undo" />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        className="toolbar-item"
        aria-label="Redo">
        <i className="format redo" />
      </button>

      <select
        id="fontSizeSelect"
        onChange={changeFontSize}
        value={fontSize}
        className="toolbar-item spaced"
        aria-label="Font Size">
        {fontSizes.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
      <select
        id="fontFamilySelect"
        onChange={changeFontFamily}
        value={fontFamily}
        className="toolbar-item spaced"
        aria-label="Font Family">
        {fontFamilies.map((font) => (
          <option key={font} value={font}>
            {font}
          </option>
        ))}
      </select>

     
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        aria-label="Format Bold">
        <i className="format bold" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        aria-label="Format Italics">
        <i className="format italic" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        aria-label="Format Underline">
        <i className="format underline" />
      </button>
      {/* <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
        className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
        aria-label="Format Strikethrough">
        <i className="format strikethrough" />
      </button> */}
{/* 
      <button
        onClick={() => formatHeading(1)}
        className="toolbar-item spaced"
        aria-label="Format H1"
        style={{opacity:0.5}}>
        H1
      </button> */}
      {/* <button
        onClick={() => formatHeading(2)}
        className="toolbar-item spaced"
        aria-label="Format H2"
        style={{opacity:0.5}}>
        H2
      </button> */}

      <button
        onClick={() => {
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        aria-label="Insert Ordered List">
        <i className="format list-ol" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        aria-label="Insert Unordered List">
        <i className="format list-ul" />
      </button>

      {/* <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className="toolbar-item spaced"
        aria-label="Left Align">
        <i className="format left-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className="toolbar-item spaced"
        aria-label="Center Align">
        <i className="format center-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className="toolbar-item spaced"
        aria-label="Right Align">
        <i className="format right-align" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className="toolbar-item"
        aria-label="Justify Align">
        <i className="format justify-align" />
      </button> */}
    </div>
  );
}