import { jsPDF } from "jspdf";
import React, { useState } from "react";
import { useStore } from "../Store";
import { format, parseJSON } from "date-fns";
import { el, fr } from "date-fns/locale";
import {
  IconCertificate,
  IconFileCertificate,
  IconFileCheck,
  IconPillFilled,
  IconPrinter,
} from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import {
  Group,
  Button,
  Switch,
  SegmentedControl,
  Stack,
  Text,
} from "@mantine/core";
import { CreateCertificat } from "./AddDocumentCertificat";

export const GenerateOrdonnanceLibre = ({ ordoType }) => {
  const {
    ordonnanceLibreDate,
    setOrdonnanceLibreDate,
    setOrdonnanceLibreData,
    editorState,
    selectedPatientInfo,
  } = useStore();
  const [tttAld, setTttAld] = useState(false);
  const [type, setType] = useState(ordoType);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
  };

  const date = ordonnanceLibreDate;
  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;
    let yPos = 50;
    const lineSpacing = 5;
    const itemSpacing = 10;
    const headerSpacing = 15;
    const footerHeight = 10;
    const textHeight = 6;

    // Fonction pour ajouter l'en-tête
    const addHeader = () => {
      doc.setFontSize(16);
      doc.setFont("Optima", "bold");
      doc.text("Dr. Leon PACCABLE", 15, 15, { align: "left" });
      doc.setFont("Optima", "bolditalic");
      doc.setFontSize(12);
      doc.text("Spécialiste Médecine Générale", 15, 20, { align: "left" });
      doc.setFont("Optima", "normal");
      doc.setFontSize(11);
      doc.text("Ancien Interne des Hôpitaux de Bordeaux", 15, 25, {
        align: "left",
      });
      doc.text("123 Rue de l'Hôpital, 75001 Paris", 15, 30, { align: "left" });
      doc.text("Tel: 0576543234", 15, 35, { align: "left" });
      doc.setFont("Optima", "bold");
      doc.text("Rendez-vous sur Doctolib.fr", 15, 40, { align: "left" });
      doc.setFont("Times", "italic");
      yPos += 5;
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Rpps.png",
        "PNG",
        pageWidth / 2 - 10,
        15,
        40,
        23
      );
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Adeli.png",
        "PNG",
        pageWidth - 60,
        15,
        40,
        23
      );
    };

    // Fonction pour ajouter le pied de page
    const addFooter = () => {
      doc.setFontSize(10);
      doc.text(
        "Page " + doc.internal.getNumberOfPages(),
        pageWidth / 2,
        pageHeight - 10,
        { align: "center" }
      );

      doc.addImage(
        "https://www.iavicenne.fr/static/img/qr_code.png",
        "PNG",
        pageWidth - 25,
        pageHeight - 25,
        20,
        20
      );
    };

    // Fonction pour ajouter une nouvelle page
    const addNewPage = () => {
      doc.addPage();
      addHeader();
      yPos = 50;
    };

    // Fonction pour ajouter le nom du patient et la date
    const addPatientNameAndDate = () => {
      if (type === "Ordonnance") {
        doc.setFont("Times", "normal");
        doc.setFontSize(12);
        addText(`Fait le ${formatDate(date)}`, margin);
        yPos += 5;

        const patientName = `Monsieur ${selectedPatientInfo.NomNaissance} ${selectedPatientInfo.ListePrenom}`;
        const patientNameX = pageWidth / 2;

        doc.setFont("Times", "bold");
        doc.setFontSize(15);
        doc.text(patientName, patientNameX, yPos, { align: "center" });

        // Ajouter un soulignement sous le nom du patient
        const textWidth = doc.getTextWidth(patientName);
        doc.line(
          patientNameX - textWidth / 2,
          yPos + 1,
          patientNameX + textWidth / 2,
          yPos + 1
        ); // +2 pour positionner la ligne juste en dessous du texte
      } else if (type === "Certificat") {
        yPos += 5;
        const CName = `CERTIFICAT MÉDICAL`;
        const CNameX = pageWidth / 2;

        doc.setFont("Times", "bold");
        doc.setFontSize(18);
        doc.text(CName, CNameX, yPos, { align: "center" });

        // Ajouter un soulignement sous le nom du patient
        const textWidth = doc.getTextWidth(CName);
        doc.line(
          CNameX - textWidth / 2,
          yPos + 1,
          CNameX + textWidth / 2,
          yPos + 1
        ); // +2 pour positionner la ligne juste en dessous du texte
      }
      yPos += 10;
    };

    // Fonction pour ajouter du texte avec gestion automatique des retours à la ligne et nouvelles pages
    const addText = (
      text,
      x,
      fontSize = 12,
      maxWidth = pageWidth - 20,
      drawLine = false,
      indent = 0
    ) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, 170);

      lines.forEach((line) => {
        if (yPos + textHeight > pageHeight - footerHeight - itemSpacing) {
          addNewPage();
          yPos += 5;
          addPatientNameAndDate();
          yPos += 5;
        }
        doc.text(line, x, yPos);
        yPos += textHeight;
        if (drawLine) {
          const lineWidth = doc.getTextWidth(line);
          doc.line(
            margin + indent,
            yPos - 5,
            margin + indent + lineWidth,
            yPos - 5
          );
        }
      });
    };

    const addEditorText = () => {
      try {
        const rootChildren = JSON.parse(editorState).root.children;
        rootChildren.forEach((element) => {
          // console.log(element)
          if (element.type === "paragraph") {
            console.log(element);

            const textNode = element.children[0];
            const text = textNode?.text;
            if (text) {
              let fontStyle = "normal";

              // Définir la police
              let fontFamily = "Times";
              let fontSize = 12;
              if (textNode.style && textNode.style.includes("font-family")) {
                fontFamily = textNode.style.split(";")[0].split(":")[1].trim();
              }
              if (textNode.style && textNode.style.includes("font-size")) {
                fontSize = textNode.style
                  .split(";")[0]
                  .split(":")[1]
                  .trim()
                  .replace("px", "");
              }
              // Définir le style de police
              if (textNode.format === 9 || textNode.format === 1) {
                fontStyle = "bold";
              } else if (textNode.format === 2) {
                fontStyle = "italic";
              } else if (textNode.format === 3) {
                fontStyle = "bolditalic";
              }

              doc.setFont(fontFamily, fontStyle);
              if (textNode.format !== 9) {
                addText(text, margin, fontSize, yPos);
              }
              if (textNode.format === 9) {
                addText(text, margin, fontSize, yPos, true);
              }
            } else {
              yPos += 5;
            }
          } else if (element.type === "list") {
            function processListItems(items, depth = 0) {
              items.forEach((listItem, index) => {
                let fontFamily = "Times";
                let fontStyle = "normal";
                let fontSize = 12;
                let textNode;
                let text;
                const indent = margin + 5 + depth * 10; // 10 pixels d'indentation par niveau

                if (listItem.type === "listitem") {
                  textNode = listItem.children[0];
                  if (textNode.type === "text") {
                    if (
                      textNode.style &&
                      textNode.style.includes("font-family")
                    ) {
                      fontFamily = textNode.style
                        .split(";")[0]
                        .split(":")[1]
                        .trim();
                    }
                    if (
                      textNode.style &&
                      textNode.style.includes("font-size")
                    ) {
                      fontSize = textNode.style
                        .split(";")[0]
                        .split(":")[1]
                        .trim()
                        .replace("px", "");
                    }
                    // Définir le style de police
                    if (textNode.format === 9 || textNode.format === 1) {
                      fontStyle = "bold";
                    } else if (textNode.format === 2) {
                      fontStyle = "italic";
                    } else if (textNode.format === 3) {
                      fontStyle = "bolditalic";
                    }

                    let tag = "ul";
                    if (element.tag === "ol" && listItem.tag !== "ul") {
                      tag = "ol";
                    }
                    if (element.tag === "ol" && listItem.tag === "ul") {
                      tag = "ul";
                    }
                    if (element.tag === "ul" && listItem.tag !== "ol") {
                      tag = "ul";
                    }
                    if (element.tag === "ul" && listItem.tag === "ol") {
                      tag = "ol";
                    }

                    if (tag === "ol") {
                      text = `${index + 1}. ${textNode.text}`;
                    }

                    if (element.tag === "ul") {
                      text = `• ${textNode.text}`;
                    }
                  } else {
                    // Vérifier s'il y a des sous-éléments
                    const subItems = listItem.children.filter(
                      (child) => child.type === "list"
                    );
                    if (subItems.length > 0) {
                      processListItems(subItems[0].children, depth + 1);
                    } else if (listItem.type === "list") {
                      // C'est une sous-liste directe
                      processListItems(listItem.children, depth + 1);
                    }
                  }
                  if (text) {
                    doc.setFont(fontFamily, fontStyle);
                    if (textNode.format === 9 || textNode.format === 8) {
                      addText(
                        text,
                        indent,
                        fontSize,
                        yPos,
                        true,
                        5 + depth * 10
                      );
                    } else {
                      addText(text, indent, fontSize, yPos);
                    }
                  }
                }
              });
            }

            processListItems(element.children);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    // Ajout de l'en-tête
    addHeader();

    // Ajout du nom du patient et de la date en haut de la première page
    addPatientNameAndDate();

    // Séparation des données de l'ordonnance en deux catégories

    let index = 1; // Initialisation de l'index global

    // Ajout des données tttAld === true avec les séparateurs et le texte
    if (tttAld) {
      doc.setFontSize(10);
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.text(
        "Prescriptions relatives au traitement de l'affection de longue durée reconnue (liste ou hors liste)",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(AFFECTIONS EXONERANTE)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;

      // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
      if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
        addNewPage();
        yPos += 5;
        addPatientNameAndDate();
        yPos += 5;
      }

      doc.setFont("Times", "bold");
      doc.setFontSize(12);

      addEditorText();

      yPos += 1; // Espacement
      index++; // Incrémentation de l'index global

      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text(
        "Prescriptions SANS RAPPORT avec l'affection de longue durée",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(MALADIES INTERCURRENTES)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;
    }

    if (!tttAld) {
      yPos += 5;

      // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
      if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
        addNewPage();
        yPos += 5;
        addPatientNameAndDate();
        yPos += 5;
      }
      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      addEditorText();
    }
    // Ajout du pied de page sur toutes les pages
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addFooter();
    }

    // Générer le blob et ouvrir dans un nouvel onglet
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
    // doc.autoPrint();

    const OrdonnanceLibreData = {
      date: date,
      tttAld: tttAld,
      editorState: JSON.parse(editorState),
    };
    setOrdonnanceLibreData(OrdonnanceLibreData);
  };

  return (
    <Stack gap="xs" align="stretch" justify="flex-start" style={{marginBottom:5}}>
      <Group justify="space-between" align="baseline">
        <SegmentedControl
          withItemsBorders={false}
          data={[
            {
              value: "Ordonnance",
              label: (
                <Group gap="xs" justify="center">
                  <Text size="xs">
                    <IconFileCheck
                      size="16"
                      style={{
                        verticalAlign: "middle",
                        marginRight: 4,
                      }}
                    />
                    Ordonnance
                  </Text>
                </Group>
              ),
            },
            {
              value: "Certificat",
              label: (
                <Group gap="xs" justify="center">
                  <Text size="xs">
                    {" "}
                    <IconFileCertificate
                      size="16"
                      style={{
                        verticalAlign: "middle",
                        marginRight: 4,
                      }}
                    />
                    Certificat
                  </Text>
                </Group>
              ),
            },
          ]}
          onChange={setType}
          color="#94ADA7"
          radius="md"
          size="xs"
          transitionDuration={500}
          transitionTimingFunction="linear"
          w={200}
        />

        <Group justify="flex-end">
          {type === "Ordonnance" && (
            <Switch
              size="md"
              onLabel="ALD"
              offLabel="ALD"
              checked={tttAld === true}
              onChange={(event) => setTttAld(event.currentTarget.checked)}
            />
          )}

          <DateInput
            variant="filled"
            valueFormat="DD/MM/YYYY"
            size="xs"
            radius="md"
            placeholder="Input placeholder"
            defaultValue={new Date()}
            value={date}
            onChange={setOrdonnanceLibreDate}
          />
          <Button
            className="printButton"
            variant="outline"
            size="xs"
            color="#94ADA7"
            radius="md"
            style={{ boxShadow: "0 2px 5px #bebebe" }}
            onClick={generatePDF}
          >
            <IconPrinter color="#94ADA7" size={19} />
          </Button>
        </Group>
      </Group>
      {type === "Certificat" && (
        <Group>
          <CreateCertificat
            type="Sport"
            tooltipText="Certificat de non contre-indication à la pratique du sport"
          />
          {/* <CreateCertificat type='Absence' tooltipText="Certificat justifiant l'absence du patient"/>
      <CreateCertificat type='Absence de la Mère' tooltipText="Certificat justifiant l'absence de la Mère"/>
      <CreateCertificat type='Absence du Père' tooltipText="Certificat justifiant l'absence du Père"/>
      <CreateCertificat type='Aptitude' tooltipText="Certificat justifiant l'absence du patient"/>
      <CreateCertificat type='Violences'/>
      <CreateCertificat type='Tiers'/>
      <CreateCertificat type='Tiers (urgence)'/>
      <CreateCertificat type='Tiers (péril imminent)'/>
      <CreateCertificat type='Tiers (décision du représentant de l’Etat)'/> */}
        </Group>
      )}
    </Stack>
  );
};

export const ShowOldOrdonnanceLibre = (oldOrdonnanceLibredata) => {
  const [oldEditorState, setOldEditorState] = useState(
    oldOrdonnanceLibredata.data.editorState
  );
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const [tttAld, setTttAld] = useState(oldOrdonnanceLibredata.data.tttAld);
  const [date, setDate] = useState(oldOrdonnanceLibredata.data.date);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
  };

  const generateOldPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;
    let yPos = 50;
    const lineSpacing = 5;
    const itemSpacing = 10;
    const headerSpacing = 15;
    const footerHeight = 10;
    const textHeight = 6;

    // Fonction pour ajouter l'en-tête
    const addHeader = () => {
      doc.setFontSize(16);
      doc.setFont("Optima", "bold");
      doc.text("Dr. Leon PACCABLE", 15, 15, { align: "left" });
      doc.setFont("Optima", "bolditalic");
      doc.setFontSize(12);
      doc.text("Spécialiste Médecine Générale", 15, 20, { align: "left" });
      doc.setFont("Optima", "normal");
      doc.setFontSize(11);
      doc.text("Ancien Interne des Hôpitaux de Bordeaux", 15, 25, {
        align: "left",
      });
      doc.text("123 Rue de l'Hôpital, 75001 Paris", 15, 30, { align: "left" });
      doc.text("Tel: 0576543234", 15, 35, { align: "left" });
      doc.setFont("Optima", "bold");
      doc.text("Rendez-vous sur Doctolib.fr", 15, 40, { align: "left" });
      doc.setFont("Times", "italic");
      yPos += 5;
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Rpps.png",
        "PNG",
        pageWidth / 2 - 10,
        15,
        40,
        23
      );
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Adeli.png",
        "PNG",
        pageWidth - 60,
        15,
        40,
        23
      );
    };

    // Fonction pour ajouter le pied de page
    const addFooter = () => {
      doc.setFontSize(10);
      doc.text(
        "Page " + doc.internal.getNumberOfPages(),
        pageWidth / 2,
        pageHeight - 10,
        { align: "center" }
      );

      doc.addImage(
        "https://www.iavicenne.fr/static/img/qr_code.png",
        "PNG",
        pageWidth - 25,
        pageHeight - 25,
        20,
        20
      );
    };

    // Fonction pour ajouter une nouvelle page
    const addNewPage = () => {
      doc.addPage();
      addHeader();
      yPos = 50;
    };

    // Fonction pour ajouter le nom du patient et la date
    const addPatientNameAndDate = () => {
      doc.setFont("Times", "normal");
      doc.setFontSize(12);
      addText(`Fait le ${formatDate(date)}`, margin);
      doc.setFont("Times", "bold");
      doc.setFontSize(27);
      doc.setTextColor("red");
      doc.text("DUPLICATA", 110, 50, {
        align: "left",
      });
      doc.setTextColor("black");
      yPos += 5;

      const patientName = `Monsieur ${selectedPatientInfo.NomNaissance} ${selectedPatientInfo.ListePrenom}`;
      const patientNameX = pageWidth / 2;

      doc.setFont("Times", "bold");
      doc.setFontSize(15);
      doc.text(patientName, patientNameX, yPos, { align: "center" });

      // Ajouter un soulignement sous le nom du patient
      const textWidth = doc.getTextWidth(patientName);
      doc.line(
        patientNameX - textWidth / 2,
        yPos + 1,
        patientNameX + textWidth / 2,
        yPos + 1
      ); // +2 pour positionner la ligne juste en dessous du texte
      yPos += 10;
    };

    // Fonction pour ajouter du texte avec gestion automatique des retours à la ligne et nouvelles pages
    const addText = (
      text,
      x,
      fontSize = 12,
      maxWidth = pageWidth - 20,
      drawLine = false,
      indent = 0
    ) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, 170);

      lines.forEach((line) => {
        if (yPos + textHeight > pageHeight - footerHeight - itemSpacing) {
          addNewPage();
          yPos += 5;
          addPatientNameAndDate();
          yPos += 5;
        }
        doc.text(line, x, yPos);
        yPos += textHeight;
        if (drawLine) {
          const lineWidth = doc.getTextWidth(line);
          doc.line(
            margin + indent,
            yPos - 5,
            margin + indent + lineWidth,
            yPos - 5
          );
        }
      });
    };

    const addEditorText = () => {
      try {
        const rootChildren = oldEditorState.root.children;
        rootChildren.forEach((element) => {
          // console.log(element)
          if (element.type === "paragraph") {
            console.log(element);

            const textNode = element.children[0];
            const text = textNode?.text;
            if (text) {
              let fontStyle = "normal";

              // Définir la police
              let fontFamily = "Times";
              let fontSize = 12;
              if (textNode.style && textNode.style.includes("font-family")) {
                fontFamily = textNode.style.split(";")[0].split(":")[1].trim();
              }
              if (textNode.style && textNode.style.includes("font-size")) {
                fontSize = textNode.style
                  .split(";")[0]
                  .split(":")[1]
                  .trim()
                  .replace("px", "");
              }
              // Définir le style de police
              if (textNode.format === 9 || textNode.format === 1) {
                fontStyle = "bold";
              } else if (textNode.format === 2) {
                fontStyle = "italic";
              } else if (textNode.format === 3) {
                fontStyle = "bolditalic";
              }

              doc.setFont(fontFamily, fontStyle);
              if (textNode.format !== 9) {
                addText(text, margin, fontSize, yPos);
              }
              if (textNode.format === 9) {
                addText(text, margin, fontSize, yPos, true);
              }
            } else {
              yPos += 5;
            }
          } else if (element.type === "list") {
            function processListItems(items, depth = 0) {
              items.forEach((listItem, index) => {
                let fontFamily = "Times";
                let fontStyle = "normal";
                let fontSize = 12;
                let textNode;
                let text;
                const indent = margin + 5 + depth * 10; // 10 pixels d'indentation par niveau

                if (listItem.type === "listitem") {
                  textNode = listItem.children[0];
                  if (textNode.type === "text") {
                    if (
                      textNode.style &&
                      textNode.style.includes("font-family")
                    ) {
                      fontFamily = textNode.style
                        .split(";")[0]
                        .split(":")[1]
                        .trim();
                    }
                    if (
                      textNode.style &&
                      textNode.style.includes("font-size")
                    ) {
                      fontSize = textNode.style
                        .split(";")[0]
                        .split(":")[1]
                        .trim()
                        .replace("px", "");
                    }
                    // Définir le style de police
                    if (textNode.format === 9 || textNode.format === 1) {
                      fontStyle = "bold";
                    } else if (textNode.format === 2) {
                      fontStyle = "italic";
                    } else if (textNode.format === 3) {
                      fontStyle = "bolditalic";
                    }

                    let tag = "ul";
                    if (element.tag === "ol" && listItem.tag !== "ul") {
                      tag = "ol";
                    }
                    if (element.tag === "ol" && listItem.tag === "ul") {
                      tag = "ul";
                    }
                    if (element.tag === "ul" && listItem.tag !== "ol") {
                      tag = "ul";
                    }
                    if (element.tag === "ul" && listItem.tag === "ol") {
                      tag = "ol";
                    }

                    if (tag === "ol") {
                      text = `${index + 1}. ${textNode.text}`;
                    }

                    if (element.tag === "ul") {
                      text = `• ${textNode.text}`;
                    }
                  } else {
                    // Vérifier s'il y a des sous-éléments
                    const subItems = listItem.children.filter(
                      (child) => child.type === "list"
                    );
                    if (subItems.length > 0) {
                      processListItems(subItems[0].children, depth + 1);
                    } else if (listItem.type === "list") {
                      // C'est une sous-liste directe
                      processListItems(listItem.children, depth + 1);
                    }
                  }
                  if (text) {
                    doc.setFont(fontFamily, fontStyle);
                    if (textNode.format === 9 || textNode.format === 8) {
                      addText(
                        text,
                        indent,
                        fontSize,
                        yPos,
                        true,
                        5 + depth * 10
                      );
                    } else {
                      addText(text, indent, fontSize, yPos);
                    }
                  }
                }
              });
            }

            processListItems(element.children);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    // Ajout de l'en-tête
    addHeader();

    // Ajout du nom du patient et de la date en haut de la première page
    addPatientNameAndDate();

    // Séparation des données de l'ordonnance en deux catégories

    let index = 1; // Initialisation de l'index global

    // Ajout des données tttAld === true avec les séparateurs et le texte
    if (tttAld) {
      doc.setFontSize(10);
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.text(
        "Prescriptions relatives au traitement de l'affection de longue durée reconnue (liste ou hors liste)",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(AFFECTIONS EXONERANTE)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;

      // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
      if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
        addNewPage();
        yPos += 5;
        addPatientNameAndDate();
        yPos += 5;
      }

      doc.setFont("Times", "bold");
      doc.setFontSize(12);

      addEditorText();

      yPos += 1; // Espacement
      index++; // Incrémentation de l'index global

      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text(
        "Prescriptions SANS RAPPORT avec l'affection de longue durée",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(MALADIES INTERCURRENTES)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;
    }

    if (!tttAld) {
      yPos += 5;

      // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
      if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
        addNewPage();
        yPos += 5;
        addPatientNameAndDate();
        yPos += 5;
      }
      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      addEditorText();
    }
    // Ajout du pied de page sur toutes les pages
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addFooter();
    }

    // Générer le blob et ouvrir dans un nouvel onglet
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
    // doc.autoPrint();
  };

  return (
    <Button
      className="printButton"
      variant="outline"
      size="xs"
      color="#94ADA7"
      radius="md"
      style={{ boxShadow: "0 2px 5px #bebebe" }}
      onClick={generateOldPDF}
    >
      <IconPrinter color="#94ADA7" size={19} />
    </Button>
  );
};
