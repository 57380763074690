import { useStore } from "../Store";
import { useEffect } from "react";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { IconAi, IconBrain, IconCheck } from "@tabler/icons-react";
import { Button, Group, Image, Text, Loader } from "@mantine/core";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;









export function StructureConsultData() {
  const currentNotification = useStore((state) => state.currentNotification);
  const setCurrentNotification = useStore(
    (state) => state.setCurrentNotification
  );
  const consultationTranscription = useStore(
    (state) => state.consultationTranscription
  );
  const setConsultationTranscription = useStore(
    (state) => state.setConsultationTranscription
  );
  const observationValues = useStore(
    (state) => state.observationValues
  );
  const setObservationValues = useStore(
    (state) => state.setObservationValues
  );

  const fetchData = async () => {
   try{

    if (consultationTranscription){
    notifications.clean()
    const notificationId = notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: '0', display: 'block' }}
            />
            <Text fw={700}>Structuration en Cours</Text>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Group>
        ),
        opacity: 0.7,
        autoClose: false,
        withCloseButton: true,


      });
      setCurrentNotification(notificationId);
    
    const response = await client.get(`api/structure/?transcription=${consultationTranscription}`)
    if (response.status === 200){
       setObservationValues(response.data.transcription)
       console.log(response.data.transcription)
       
    }else(console.log(response))

   }}catch(error){
    console.log(error)
   }
  };
  useEffect(() => {
    fetchData();
  }, [consultationTranscription]);
  
}
