import { jsPDF } from "jspdf";
import React, {useEffect} from "react";
import { useStore } from "../Store";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { IconDownload, IconPrinter } from "@tabler/icons-react";
import { Button } from "@mantine/core";
import axios from "axios";
import { CourrierMedical } from "./GenerateCourrier";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;





const GenerateCourrier = () => {
  const {
    currentUser,
    courrierData,
    currentSessionInfo,
    selectedPatientInfo,
    observationData,
    setCourrierData,
    setFilesToUpload,
    setDocuments,
    shouldSaveCourrier,
    setShouldSaveCourrier
  } = useStore();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
  };

  const formatDate_2 = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy", { locale: fr });
  };

  const GeneratePDF = () => {
    if (!selectedPatientInfo || !courrierData) {
      console.error('Missing required data for PDF generation');
      return;
    }
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;
    let yPos = 50;
    const lineSpacing = 5;
    const itemSpacing = 10;
    const headerSpacing = 15;
    const footerHeight = 10;
    const textHeight = 6;

    // Fonction pour ajouter l'en-tête
    const addHeader = () => {
      doc.setFontSize(16);
      doc.setFont("Optima", "bold");
      doc.text("Dr. Leon PACCABLE", 15, 15, { align: "left" });
      doc.setFont("Optima", "bolditalic");
      doc.setFontSize(12);
      doc.text("Spécialiste Médecine Générale", 15, 20, { align: "left" });
      doc.setFont("Optima", "normal");
      doc.setFontSize(11);
      doc.text("Ancien Interne des Hôpitaux de Bordeaux", 15, 25, {
        align: "left",
      });
      doc.text("123 Rue de l'Hôpital, 75001 Paris", 15, 30, { align: "left" });
      doc.text("Tel: 0576543234", 15, 35, { align: "left" });
      doc.setFont("Optima", "bold");
      doc.text("Rendez-vous sur Doctolib.fr", 15, 40, { align: "left" });
      doc.setFont("Times", "italic");
      yPos += 5;
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Rpps.png",
        "PNG",
        pageWidth / 2 - 10,
        15,
        40,
        23
      );
      doc.addImage(
        "https://www.iavicenne.fr/static/img/Adeli.png",
        "PNG",
        pageWidth - 60,
        15,
        40,
        23
      );
    };

    // Fonction pour ajouter le pied de page
    const addFooter = () => {
      doc.setFontSize(10);
      doc.text(
        "Page " + doc.internal.getNumberOfPages(),
        pageWidth / 2,
        pageHeight - 10,
        { align: "center" }
      );

      doc.addImage(
        "https://www.iavicenne.fr/static/img/qr_code.png",
        "PNG",
        pageWidth - 25,
        pageHeight - 25,
        20,
        20
      );
    };

    // Fonction pour ajouter une nouvelle page
    const addNewPage = () => {
      doc.addPage();
      addHeader();
      yPos = 50;
    };

    // Fonction pour ajouter le nom du patient et la date
    const addPatientNameAndDate = () => {
      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      addText(`Courrier Médical du ${formatDate(new Date())}`, margin);
      yPos += 5;

      const patientName = `${selectedPatientInfo.ListePrenom} ${
        selectedPatientInfo.NomNaissance
      } ( ${formatDate_2(selectedPatientInfo.birthDate)} )`;
      const patientNameX = margin;
      const destinataire = courrierData.destinataire;

      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text("Destinataire", patientNameX, yPos, { align: "left" });
      const textWidth_1 = doc.getTextWidth("destinataire");
      doc.line(
        patientNameX,
        yPos + 0.5,
        patientNameX + textWidth_1,
        yPos + 0.5
      ); // +2 pour positionner la ligne juste en dessous du texte
      yPos += 5;
      doc.setFont("Times", "normal");
      doc.setFontSize(12);
      doc.text(destinataire, patientNameX, yPos, { align: "left" });
      yPos += 7;

      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text("Patient", patientNameX, yPos, { align: "left" });
      const textWidth = doc.getTextWidth("patient");
      doc.line(patientNameX, yPos + 0.5, patientNameX + textWidth, yPos + 0.5); // +2 pour positionner la ligne juste en dessous du texte
      yPos += 5;
      doc.setFont("Times", "normal");
      doc.setFontSize(12);
      doc.text(patientName, patientNameX, yPos, { align: "left" });

      // Ajouter un soulignement sous le nom du patient
      yPos += 7;

      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text("Antécédents", patientNameX, yPos, { align: "left" });
      const textWidth_3 = doc.getTextWidth("Antécédents");
      doc.line(
        patientNameX,
        yPos + 0.5,
        patientNameX + textWidth_3,
        yPos + 0.5
      ); // +2 pour positionner la ligne juste en dessous du texte
      yPos += 5;
      doc.setFont("Times", "normal");
      doc.setFontSize(12);

      // Ajouter un soulignement sous le nom du patient
    };

    // Fonction pour ajouter du texte avec gestion automatique des retours à la ligne et nouvelles pages
    const addText = (
      text,
      x,
      fontSize = 12,
      maxWidth = pageWidth - 2 * margin
    ) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (yPos + textHeight > pageHeight - footerHeight - itemSpacing) {
          addNewPage();
          yPos += 5;
          addPatientNameAndDate();
          yPos += 5;
        }
        doc.text(line, x, yPos);
        yPos += textHeight;
      });
    };
    // Ajout de l'en-tête
    addHeader();

    // Ajout du nom du patient et de la date en haut de la première page
    addPatientNameAndDate();

    // Séparation des données de l'ordonnance en deux catégories

    let index = 1; // Initialisation de l'index global

    // Ajout des données tttAld === true avec les séparateurs et le texte
    doc.setFont("Times", "italic");
    doc.setFontSize(11);
    addText(`${courrierData.antecedents}`, margin, 12, pageWidth - 2 * margin);
    yPos += 10;
    doc.setFontSize(10);

    // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
    if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
      addNewPage();
      yPos += 5;
      addPatientNameAndDate();
      yPos += 5;
    }

    doc.setFont("Times", "normal");
    doc.setFontSize(12);
    addText(`${courrierData.motif}`, margin, 12, pageWidth - 2 * margin);
    yPos += 5;
    addText(`${courrierData.content}`, margin, 12, pageWidth - 2 * margin);
    yPos += 5;
    addText(`${courrierData.politesse}`, margin, 12, pageWidth - 2 * margin);
    yPos += 10;

    doc.setFont("Times", "italic");
    doc.setFontSize(11);
    addText(`Dr Leon Paccable`, pageWidth / 2, 12, pageWidth - 2 * margin);

    yPos += 1; // Espacement
    index++; // Incrémentation de l'index global

    // Ajout du pied de page sur toutes les pages
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addFooter();
    }

    if (!shouldSaveCourrier && courrierData.content.length > 0) {
      // Générer le blob et ouvrir dans un nouvel onglet
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
      // doc.autoPrint();
    }


    if (shouldSaveCourrier && courrierData.content.length > 0) {
      // Générer le blob et ouvrir dans un nouvel onglet
    const saveCourrier = async () => {
      try {
        const pdfBlob = doc.output("blob");
        const formData = new FormData();
        const matched_patient = JSON.stringify({
          matched_patients: [
            {
              id: currentSessionInfo.subject,
              nom: selectedPatientInfo.NomNaissance,
              prenom: selectedPatientInfo.ListePrenom,
            },
          ],
        });
        formData.append("file", pdfBlob, "document.pdf");
        formData.append("practitioner", currentSessionInfo.practitioner);
        formData.append("patient", currentSessionInfo.subject);
        formData.append("title", observationData.motif);
        formData.append("type", "courrier");
        formData.append("size", pdfBlob.size.toString());
        formData.append("metadata", matched_patient);

        const response = await client.post("/api/documents/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 201) {
          console.log("Courrier Saved");
          setCourrierData([])
          try {
            // D'abord, récupérez les données actuelles de l'encounter
           
            const getCurrentEncounter = await client.get(`api/encounters/${currentSessionInfo.encounter}/`);
            const currentEncounterData = getCurrentEncounter.data.encounterData || {};

            // Ensuite, mettez à jour avec les nouvelles données
            const response2 = await client.patch(
              `api/encounters/${currentSessionInfo.encounter}/`,
              {
                encounterData: {
                  ...currentEncounterData,
                  courrierMedical: { id: response.data.id },
                },
              }
    );
            if (response.status === 200) {
              console.log("id du document enregistré");
            } else {
              console.log(response2);
            }
          } catch (err) {
            console.log(err);
            console.log("Erreur lors de la sauvegarde de l'id du document");
          }
        }
      } catch (err) {
        console.log("Erreur lors de la récupération des documents");
      }
    };
    saveCourrier()
  }

   

    // Télécharger le fichier
    // setFilesToUpload([pdfBlob]);
  };
  const isCourrierDataEmpty =
    !courrierData || !courrierData.content || courrierData.content.length === 0;

  useEffect(() => {
    if (shouldSaveCourrier) {
      GeneratePDF()
      setShouldSaveCourrier(false); // Reset the state
    }
  }, [shouldSaveCourrier]);

  return (
    <>
      <Button
        className="printButton"
        variant="outline"
        size="xs"
        color="#94ADA7"
        radius="lg"
        style={{ boxShadow: "0 2px 5px #bebebe" }}
        onClick={GeneratePDF}
        disabled={isCourrierDataEmpty}
      >
        <IconPrinter color="#94ADA7" size={19} />
      </Button>
    </>
  );
};



export default GenerateCourrier;
