import React, { useEffect, useState, useCallback } from "react";
import { Select, Loader, Group, Text } from "@mantine/core";
import { IconRadioactive, IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });


axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
async function getRadiologies(query = "") {
  const response = await client.get(`api/liste-radio/?search=${query}`);
  if (response.status !== 200) throw new Error("Network response was not ok");
  return response.data;
}

function SearchRadiologies() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const radiologieData = useStore((state) => state.radiologieData);
  const setRadiologieData = useStore((state) => state.setRadiologieData);

  const debouncedGetRadiologies = useCallback(
    debounce(async (query) => {
      try {
        const Radiologies = await getRadiologies(query);
        // console.log(Radiologies)
        const formattedData = Radiologies.map((Radiologie) => ({
          value: String(Radiologie.id),
          label: `${Radiologie.maladie} ${Radiologie.contexte ? `: ${Radiologie.contexte}` : ''} : ${Radiologie.examen}`,
          radiologie : Radiologie
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Failed to fetch Radiologies:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      debouncedGetRadiologies(query);
    } else {
      setData([]);
      setQuery("");
    }
  }, [query, debouncedGetRadiologies]);

  // Choisir une radiologie et l'enregitrer dasn l'ordonnance en cours
  const handleRadiologieClick = async (value) => {

  
    const RadiologieData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      categoryContent: String("Radiologie").trim(),
      ServiceId: parseInt(value.value, 10),
      ServiceContent: [{ radiologie: value.radiologie, tttAld:false }],
    };
    

    try {
      const isRadiologieIdPresent = radiologieData.some(
        (item) => item.serviceId === parseInt(value.value, 10)
      );
      if (isRadiologieIdPresent) {
        // Si l'entrée existe, mettre à jour
        // const existingId = response_1.data[0].id;
        console.log("cette radiologie à déja été prescrite");
      } else {
        // Si l'entrée n'existe pas, créer une nouvelle entrée
        const updatedRadiologie = [...radiologieData, RadiologieData];
        setRadiologieData(updatedRadiologie);
        console.log(radiologieData)
        const response = await client.post(
          "api/service-request/",
          RadiologieData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 201) {
          const newRadiologieData = {
            ...RadiologieData,
            id: response.data.id, // Utiliser l'ID retourné par l'API
          };
          setRadiologieData([...radiologieData, newRadiologieData]);
          console.log("radiologie mise à jour");
        } else {
          console.error("Failed to save new radiologie:", response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Select
      searchable
      clearable
      placeholder="Rechercher une Radiologie"
      data={data}
      allowDeselect={false}
      maxDropdownHeight={400}
      searchValue={query}
      value={selectedValue}
      onSearchChange={setQuery}
      onChange={(value, Radiologie) => {
        if (Radiologie) {
          handleRadiologieClick(Radiologie);
          setSelectedValue(null);
          setQuery("");
          setData([]);
        }
      }}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "lg",
        radius: "lg",
      }}
      style={{ width: "100%", marginBottom: 20 }}
      leftSection={<IconSearch size="1rem" style={{ display: "block" }} />}
      rightSection={loading && <Loader size="sm" />}
      renderOption={(props) => {
        // console.log("Props in renderOption:", props);
        return (
          <Group {...props} gap="xs" align="center">
            <Text size="xs" fw={700}>
              {" "}
              <IconRadioactive
                size="1rem"
                color="#94ADA7"
                style={{ verticalAlign: "middle", marginRight: "3" }}
              />{" "}
              {props.option.radiologie.examen}
            </Text>
            <Text size="xs" fs="italic">
            {props.option.radiologie.maladie}&nbsp;{props.option.radiologie.contexte}
            </Text>
          </Group>
        );
      }}
    />
  );
}

export default SearchRadiologies;
