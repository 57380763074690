"use client";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Stack,
  Button,
  TextInput,
  Textarea,
  Text,
  Card,
  Group,
  Container,
  Grid,
  Loader,
  Center,
  ScrollArea
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import Markdown from "react-markdown";
import {
  IconArrowAutofitUp,
  IconArrowUp,
  IconMail,
  IconPaperclip,
  IconPlane,
  IconPlaneOff,
  IconPlaneTilt,
  IconSend,
} from "@tabler/icons-react";


const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;









const StreamComponent = ({ url }) => {
  const [fullText, setFullText] = useState("");
  const [visibleChars, setVisibleChars] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      setFullText("");
      setVisibleChars(0);

      try {
        const response = await fetch(url, { signal });

        if (!response.body) {
          throw new Error("ReadableStream not yet supported in this browser.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          if (isMounted) {
            setFullText(prev => prev + value);
          }
        }
        setLoading(false);
      } catch (error) {
        if (error.name !== "AbortError" && isMounted) {
          setError(error);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [url]);

  useEffect(() => {
    if (visibleChars < fullText.length) {
      const timer = setTimeout(() => {
        setVisibleChars(prev => Math.min(prev + 1, fullText.length));
      }, 3); // Ajustez ce délai pour contrôler la vitesse d'apparition

      return () => clearTimeout(timer);
    }
  }, [fullText, visibleChars]);

  const visibleText = fullText.slice(0, visibleChars);

  return (
    <ScrollArea h={680} offsetScrollbars scrollHideDelay={100}>
    <Card
      className="ChatCard"
      withCloseButton={true}
      size="xl"
      radius="lg"
      shadow="lg"
      withBorder={true}
    >
      <Text size="sm" mb="xs" fw={500} ta="left">
        {loading && (
          <Center>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Center>
        )}
        {error && <p>Error: {error.message}</p>}
        <Markdown>{visibleText}</Markdown>
      </Text>
    </Card>
    </ScrollArea>
  );
};

export const Chat = () => {
  const [streamUrl, setStreamUrl] = useState("");
  const form = useForm({
    initialValues: {
      message: "",
    },
    validate: {
      message: (value) => (value.length < 3 ? "Votre message est vide" : null),
    },
  });
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleSubmit = async (values) => {
    console.log(values.message);
    setStreamUrl(
      `https://www.iavicenne.fr/api/chat/?message=${values.message}`
    );
    scrollToBottom();
  };
  

  return (
   

    <>
      
      <Stack>
  <Container className="ChatStreamContainer" ref={messagesContainerRef}>
    {streamUrl && <StreamComponent url={streamUrl} />}
    <form className="ChatForm" onSubmit={(e) => {
      e.preventDefault();
      handleSubmit(form.values);
    }}>
     
        <Textarea
          className="ChatTextarea"
          variant="default"
          radius="lg"
          shadow="lg"
          label=""
          placeholder="Saisissez votre Question Médicale ici...."
          {...form.getInputProps("message")}
          autosize
          minRows={5}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(form.values);
            }
          }}
         
        />
       
        <Button
          className="ChatSubmit"
          type="submit"
          radius="md"
          color="#94ADA7"
          size="xs"
          onClick={handleSubmit}
          style={{
            position: 'absolute',
            right: '10px',
            top: '20%',
            transform: 'translateY(-50%)',
          }}
          disabled={!form.values.message.trim()}
        >
          <IconArrowUp />
        </Button>
       
     
    </form>
  </Container>
</Stack>
    </>
  
  );
};
