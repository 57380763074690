import axios from "axios";


import useStore from '../Store'

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });


axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;









export { client };


export async function getPatients() {
  try {
    const response = await client.get("api/patients/");
    if (response.status !== 200) throw new Error("Network response was not ok");
    return response.data;
   
  } catch (error) {
    console.error("Failed to fetch patients:", error);
    throw error;
  }
}



