import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Card,
  Text,
  Stack,
  Alert,
  Group,
  Indicator,
  Grid,
  Divider,
  ScrollArea,
  Button,
} from "@mantine/core";
import { useStore } from "../Store";
import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

client.defaults.xsrfCookieName = "csrftoken";
client.defaults.xsrfHeaderName = "X-CSRFToken";
client.defaults.withCredentials = true;

const formatDate = (dateString) => {
  if (!dateString) return "Date non définie";
  
  const now = new Date();
  const dateToFormat = parseISO(dateString);
  
  if (now.toDateString() === dateToFormat.toDateString()) return "Aujourd'hui";
  
  return format(dateToFormat, "dd/MM/yyyy", { locale: fr });
};

export function Mailbox() {
  const email = "899700578851@mail-tests-editeur.espacedeconfiance.mssante.fr";
  const { mailboxData, setMailboxData } = useStore();
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [disabledEmails, setDisabledEmails] = useState({});
  const [error, setError] = useState(null);
  console.log({ selectedEmail: selectedEmail });

  const fetchMails = useCallback(async () => {
       // Deuxième fetch (api/mssante/fetchBAL)
       try {
        const response2 = await client.get(`api/mssante/fetchBAL/?email=${email}`);
        console.log({ response2: response2 });
        
        if (response2.status === 200) {
          // setMailboxData(prevData => ({
          //   ...prevData,
          //   email_list: [...prevData.email_list, ...(response2.data.email_list || [])]
          // }));
          setError(null);
        }
      } catch (error2) {
        console.error("Error fetching BAL:", error2);
        // Gérer l'erreur spécifique au deuxième fetch si nécessaire
      }
    try {
      // Premier fetch (api/mssante)
      const response1 = await client.get(`api/mssante/`);
      console.log({ response1: response1 });
      
      if (response1.status === 200) {
        // setMailboxData(prevData => ({
        //   ...prevData,
        //   email_list: response1.data
        // }));
        setMailboxData(
          {email_list: response1.data}
        );
        
      }
  
      setError(null);
      console.log({ mailboxData: mailboxData });
  
    } catch (error1) {
      console.error("Error fetching mails from database:", error1);
      setError(
        error1.response?.data ||
        error1.message ||
        "An error occurred while fetching mails from database"
      );
    }
  }, [client, email, setMailboxData, setError]);

  useEffect(() => {
    fetchMails();
  }, [fetchMails]);

  const HandleEmailClick =  (emailItem) => {
    setSelectedEmail(emailItem);
    // Gérer l'état désactivé pour chaque email individuel
    setDisabledEmails((prevState) => ({
      ...prevState,
      [emailItem.id]: true, // Désactiver l'email cliqué
    }));
    try {
       PatchMailData(emailItem); 
  } catch (error) {
      console.error("Erreur lors de la mise à jour de l'email :", error);  
  };
}

  const PatchMailData = async (emailItem) => {
    const newData = {
      ...emailItem,
      is_read:true,
    }
    try {
      const response = await client.put(`api/mssante/${emailItem.id}/`, newData);
      if (response.status !== 200) {
          throw new Error("La mise à jour a échoué.");
      }
  } catch (error) {
      throw new Error("Erreur lors de la mise à jour des données : " + error.message);
  }
  }

  return (
    <Grid>
      <Grid.Col span={5} className="MailsGridCol">
        <Divider my="xs" label="Liste des Messages" labelPosition="center" />
        <ScrollArea
          h="86vh"
          w="100%"
          offsetScrollbars
          scrollHideDelay={100}
          scrollbarSize={8}
        >
          <Stack gap="xs">
            {mailboxData.email_list?.map((emailItem, index) => (
              <Indicator
                key={emailItem.id || index}
                position="middle-end"
                disabled={disabledEmails[emailItem.id] || emailItem.is_read} // Désactiver si dans l'état
                size={18}
                withBorder
                style={{ marginRight: 15 }}
              >
                <Card
                  shadow="lg"
                  radius="md"
                  onClick={() => HandleEmailClick(emailItem)}
                  style={{ cursor: "pointer" }}
                >
                  <Group justify="space-between">
                    <Text size="sm" ta="left" fw={700}>
                      {emailItem.sender}
                    </Text>
                    <Text size="md" ta="right">
                      {formatDate(emailItem.date)}
                    </Text>
                  </Group>
                  <Text size="sm" ta="left">
                    {emailItem.subject}
                  </Text>
                  <Text size="sm" ta="left" c="gray" truncate="end" w="20vw">
                    {emailItem.content}
                  </Text>
                </Card>
              </Indicator>
            ))}
          </Stack>
        </ScrollArea>
      </Grid.Col>
      <Grid.Col span={7} className="MailsGridCol">
        <Divider my="xs" label="Contenu du Message" labelPosition="center" />
        {error && <Alert color="red">{error}</Alert>}
        {selectedEmail && (
          <Card shadow="lg" radius="md" style={{ height: "81.5vh" }}>
            <Stack gap="xs">
              <Group justify="space-between">
                <Text size="sm" ta="left" fw={700}>
                  De: {selectedEmail.sender}
                </Text>
                <Text size="md" ta="right">
                  {formatDate(selectedEmail.date)}
                </Text>
              </Group>
              <Group gap="xs">
                <Text size="sm" ta="left" fw={700}>
                  Sujet:
                </Text>
                <Text size="sm" ta="left">
                  {selectedEmail.subject}
                </Text>
              </Group>

              <Group gap="xs">
                <Text size="sm" ta="left" fw={700}>
                  Pièces Jointes:
                </Text>
                {selectedEmail.attachments?.map?.((document, index) => (
                  <a
                    key={index}
                    href={`https://www.iavicenne.fr/${document.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                   {document.filename}
                  </a>
                ))}
              </Group>

              <ScrollArea
                h="60vh"
                w="100%"
                offsetScrollbars
                scrollHideDelay={100}
                scrollbarSize={8}
              >
                <Text size="sm" ta="left" c="gray" style={{ marginTop: 35 }}>
                  {selectedEmail.content}
                </Text>
              </ScrollArea>
            </Stack>
          </Card>
        )}
      </Grid.Col>
    </Grid>
  );
}
