import React, { useEffect, useState, useCallback } from "react";
import { Select, Loader, } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";


const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;










function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
async function getAntecedents(query = "") {
  const response = await client.get(`api/icd-11/search/?a=${query}`);
  if (response.status !== 200) throw new Error("Network response was not ok");
  return response.data;
}


function SearchAntecedents({ handleAntecedentClick }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const setShowAntecedentsSearch = useStore((state) => state.setShowAntecedentsSearch)

  const debouncedGetAntecedents = useCallback(
    debounce(async (query) => {
     
      try {
        const Antecedents = await getAntecedents(query);

        const formattedData = Antecedents.antecedents.map(
          (Antecedent) => ({
            value: String(Antecedent.id),
            label: `${Antecedent.Title.replace(/-/g, '')} (${Antecedent.Code})`,
            icd11Data: Antecedent,
          })
        );
        console.log(formattedData)
        setData(formattedData);
      } catch (error) {
        console.error("Failed to fetch Antecedents:", error);
        
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      debouncedGetAntecedents(query);
    } else {
      setData([]);
    }
  }, [query, debouncedGetAntecedents]);

 
  const handleSelectChange = (value) => {
    const selectedAntecedent = data.find((item) => item.value === value);
    handleAntecedentClick(selectedAntecedent);
    setQuery(""); // Réinitialiser l'input après la sélection
    setData([]);  // Réinitialiser les données affichées
    setSelectedValue('')
    };

  return (
    <Select
      className="AntecedentsSelect"
      searchable
      placeholder="Rechercher une Antecedent CIM-11"
      data={data}
      allowDeselect={false}
      maxDropdownHeight={700}
      searchValue={query}
      onSearchChange={setQuery}
      value={selectedValue}
      onChange={handleSelectChange}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "lg",
        radius: "lg",
      }}
      style={{ width: "100%", marginBottom: 20 }}
      leftSection={<IconSearch size="1rem" style={{ display: "block" }} />}
      rightSection={loading && <Loader size="sm" />}
    />
  );
}



export default SearchAntecedents;