import React from "react";
import { Button } from "@mantine/core";
import {
 
  IconLogout,
  
} from "@tabler/icons-react";

function Logout() {
  return (
    <Button className="logoutButton" variant="filled" color="#94ADA7"   radius="md">
      <a href="https://www.iavicenne.fr/api/logout/">
       <IconLogout color="#fff"/>
      </a>
    </Button>
  );
}

export default Logout;