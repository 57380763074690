import React, { useEffect, useState, useCallback } from "react";
import { Select, Loader, Group, Text, Image } from "@mantine/core";
import { IconSearch, IconPill, IconCheck } from "@tabler/icons-react";
import axios from "axios";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";

const client = axios.create({ baseURL: "https://www.iavicenne.fr/" });
// const client2 = axios.create({ baseURL: "http://localhost:8000/" });

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;








function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

async function getMedicaments(query = "") {
  const response = await client.get(`api/cis-bdpm/search/?q=${query}`);
  if (response.status !== 200) throw new Error("Network response was not ok");
  return response.data;
}

function SearchMedicaments() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const ordonnanceData = useStore((state) => state.ordonnanceData);
  const setOrdonnanceData = useStore((state) => state.setOrdonnanceData);
  const selectedMedicamentDetail = useStore(
    (state) => state.selectedMedicamentDetail
  );

  const setSelectedMedicamentDetail = useStore(
    (state) => state.setSelectedMedicamentDetail
  );

  const debouncedGetMedicaments = useCallback(
    debounce(async (query) => {
      try {
        setLoading(true);
        const medicaments = await getMedicaments(query);
        const formattedData = medicaments.cis_bdpm_results.map(
          (medicament) => ({
            value: String(medicament.id),
            label: `${medicament.denomination} (${medicament.dci})`,
            medicament: medicament,
          })
        );
        setData(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch medicaments:", error);
        setLoading(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      debouncedGetMedicaments(query);
    } else {
      setData([]);
    }
  }, [query, debouncedGetMedicaments]);

  const handleMedicamentClick = async (value) => {
    console.log(currentSessionInfo);
    const medicamentData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      medoc_id: value.medicament.id,
      nom_commercial: value.medicament.denomination,
      dci: value.medicament.dci,
      dosage: value.medicament.dosage,
      forme: value.medicament.forme,
      voie: value.medicament.voie,
      commercialisation: value.medicament.commercialisation,
      ciscode: value.medicament.cisCode,
      matin: null,
      midi: null,
      soir: null,
      coucher: null,
      periode: null,
      nombre_periode: null,
      commentaire: "",
      tttAld: false,
      tttFond: false,
      nsCIF: false,
      nsEFG: false,
      nsMTE: false,
      rcp: value.medicament.rcp,
    };

    try {
      if (Array.isArray(ordonnanceData)) {
        const existingIndex = ordonnanceData.findIndex(
          (item) => item.medoc_id === value.medicament.id
        );

        if (existingIndex !== -1) {
          // Si l'entrée existe, mettre à jour
          const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Image
                  fit="contain"
                  src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                  alt="iAvicenne Logo"
                  width={25}
                  height={25}
                  style={{ margin: "0", display: "block" }}
                />
                <Text fw={700}>Médicament déjà Prescrit </Text>
              </Group>
            ),

            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,


          });
        } else {
          // Si l'entrée n'existe pas, créer une nouvelle entrée
          const response = await client.post(
            "api/medication-requests/",
            medicamentData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );

          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            setOrdonnanceData([
              ...ordonnanceData,
              { ...medicamentData, id: response.data.id },
            ]);
            console.log("Ordonnance mise à jour");

            if (!value.medicament.rcp || value.medicament.rcp.length === 0) {
              try {
                const notificationId = notifications.show({
                  withBorder: true,
                  loading: false,
                  radius: "lg",
                  color: "#94ADA7",
                  title: (
                    <Group gap="xs">
                      <Image
                        fit="contain"
                        src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                        alt="iAvicenne Logo"
                        width={25}
                        height={25}
                        style={{ margin: "0", display: "block" }}
                      />
                      <Text fw={700}>Mise à jour des Posologies</Text>
                      <Loader
                        className="ChatLoader"
                        color="#94ADA7"
                        type="dots"
                      />
                    </Group>
                  ),

                  opacity: 0.7,
                  autoClose: false,
                  withCloseButton: true,


                });

                // const response_2 = await client2.get(
                //   `rcp/?ciscode=${value.medicament.cisCode}`
                // );
                const response_2 = await client.get(
                      `api/cis-bdpm/?dci=&denomination=&cisCode=${value.medicament.cisCode}`
                    );
                if (response_2.status === 200) {
                  const medoc = ordonnanceData.filter(
                    (item) => item.medoc_id === value.medicament.id
                  );
                 console.log({'medicamentId': value.medicament.id}); 
                 console.log({'response_2': response_2}); 
                   
                  } 
                  
                  setSelectedMedicamentDetail({
                    ...medicamentData,
                    rcp: response_2.data.rcp_json,
                  });

                  notifications.update({
                    id: notificationId,
                    withBorder: true,
                    loading: false,
                    radius: "lg",
                    color: "#94ADA7",
                    title: (
                      <Group gap="xs">
                        <Image
                          fit="contain"
                          src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                          alt="iAvicenne Logo"
                          width={25}
                          height={25}
                          style={{ margin: "0", display: "block" }}
                        />
                        <Text fw={700}>Posologies à jour</Text>
                        <IconCheck
                          style={{
                            width: "1.1rem",
                            color: "#94ADA7",
                            height: "1.1rem",
                          }}
                        />
                      </Group>
                    ),

                    opacity: 0.7,
                    autoClose: 1000,
                    withCloseButton: true,


                  });
                // } else {
                //   console.error("Failed to fetch RCP");
                // }
              } catch (error) {
                console.error("Failed to fetch Medicament:", error);
              }
            }
          } else {
            console.error("Failed to save new medicament:", response);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Select
      searchable
      clearable
      placeholder="Rechercher un Médicament"
      data={data}
      allowDeselect={false}
      maxDropdownHeight={400}
      searchValue={query}
      onSearchChange={setQuery}
      value={selectedValue}
      onChange={(value) => {
        const selectedMedicament = data.find((item) => item.value === value);
        if (selectedMedicament) {
          handleMedicamentClick(selectedMedicament);
          setSelectedValue(null);
          setQuery("");
          setData([]);
        }
      }}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "lg",
        radius: "lg",
      }}
      style={{ width: "100%", marginBottom: 20 }}
      leftSection={<IconSearch size="1rem" style={{ display: "block" }} />}
      rightSection={loading && <Loader size="sm" />}
      renderOption={(props) => {
        // console.log("Props in renderOption:", props);
        return (
          <Group {...props} gap="xs" align="center">
            <Text size="xs" fw={700}>
              {" "}
              <IconPill
                size="1rem"
                color="#94ADA7"
                style={{ verticalAlign: "middle", marginRight: "3" }}
              />{" "}
              {props.option.medicament.denomination}
            </Text>
            <Text size="xs" fs="italic">
              {props.option.medicament.dci} ({props.option.medicament.voie})
            </Text>
          </Group>
        );
      }}
    />
  );
}

export default SearchMedicaments;
